import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import ControlledInput from 'components/controlledInput';
import styles from './classCodeEntry.module.scss';

export default function ClassCodeEntry() {
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const inputRef = useRef();

  /*
   *   Check class code
   */
  function checkCode(event) {
    event.preventDefault();
    if (code.trim() === '') return;
    fetch(`/api/v2/classroom?code=${code}&hide_archived=true`)
      .then((response) => {
        // Valid? Redirect to login page
        if (response.status === 200) {
          window.location = `/quickjoin/#/${code}`;
        }

        // Missing? Display error message
        if (response.status === 404) {
          setErrorMessage('This code does not exist!');
          inputRef.current.focus();
        }
      })
      .catch((error) => {
        // Something went wrong. Display error message
        console.log(error);
        setErrorMessage('Something went wrong. Please try again.');
      });
  }

  /*
   *   Hide error message when input loses focus
   */
  function handleBlur() {
    setErrorMessage(false);
  }

  /*
   *   Hide error message when user starts typing
   */
  useEffect(() => {
    setErrorMessage(false);
  }, [code]);

  /*
   *   Render
   */
  return (
    <form
      className={classnames(styles.container, {
        [styles.invalid]: errorMessage,
      })}
      onSubmit={checkCode}
    >
      <div>
        <ControlledInput
          id="class-code-input"
          ref={inputRef}
          value={code}
          onChange={setCode}
          onBlur={handleBlur}
          className={styles.input}
        />
        <label
          className={classnames(styles.label, { [styles.filled]: code !== '' })}
          htmlFor="class-code-input"
        >
          Enter student class code
        </label>
      </div>
      <div>
        <button className={styles.button}>Go</button>
      </div>
      <div
        className={styles.error}
        aria-live="assertive"
        aria-relevant="additions removals"
      >
        {errorMessage && 'Invalid code. Try again.'}
      </div>
    </form>
  );
}
