import React from 'react';
import components from './index';
import styles from './container.module.scss';

const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

const Container = ({ items, direction }) => {
  const rowWidth = 100 / items.length;
  const blocks = items?.map((block, index) => {
    const componentName = capitalize(block.componentContentType);
    let BlockComponent = components[componentName];

    if (!BlockComponent) return null;

    if (direction === 'row') {
      return (
        <div className={styles.row} style={{ width: `${rowWidth}%` }}>
          <BlockComponent
            isFirstElement={index === 0}
            comesFromContainer={true}
            {...block}
          />
        </div>
      );
    }

    return (
      <div className={styles.row}>
        <BlockComponent
          isFirstElement={index === 0}
          comesFromContainer={true}
          {...block}
        />
      </div>
    );
  });

  return <div className={styles.container}>{blocks}</div>;
};

export default Container;
