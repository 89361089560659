import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import forceLayout from 'helpers/forceLayout';
import Typewriter from 'typewriter-effect/dist/core';
import styles from './heroTypewriter.module.scss';

export default function HeroTypewriter({ title }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const typewriterRef = useRef(null);
  const typeContainerRef = useRef(null);
  const [prefix, text, suffix] = title.split('/');

  /*
   *   Use Effect
   */
  useEffect(() => {
    forceLayout();
    requestAnimationFrame(() => {
      setIsLoaded(true);
      if (typeContainerRef) {
        typewriterRef.current = new Typewriter(typeContainerRef.current, {
          delay: 50,
        });
        typewriterRef.current.typeString(text).start();
      }
    });
  }, [setIsLoaded, text]);

  /*
   *   Render
   */
  return (
    <section
      className={classnames(styles.container, {
        [styles.loaded]: isLoaded,
      })}
    >
      <div className={styles.inner}>
        <div className={styles.title}>
          <div className={styles.title__addon}>{prefix}</div>
          <span className={styles.typewriter} ref={typeContainerRef}></span>
          <div className={styles.title__addon}>{suffix}</div>
        </div>
      </div>
    </section>
  );
}
