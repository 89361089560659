import React from 'react';
import classnames from 'classnames';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import colorTheme from 'helpers/colorTheme';
import SmartLink from 'components/smartLink';
import Media from './media';
import RichText from 'components/richText';
import styles from './textAndGraphic.module.scss';

export default function TextAndGraphic({
  title,
  label,
  tagIntro,
  graphic,
  copy,
  copyTitle,
  buttonText,
  buttonUrl,
  secondButtonText,
  secondButtonUrl,
  alignment,
  backgroundColor,
  sectionId,
  isFirstElement,
  backgroundImage,
}) {
  const [containerRef, inView] = useInView({ triggerOnce: true });

  return (
    <section
      id={sectionId}
      style={{
        ...(backgroundImage && {
          backgroundImage: `url(${backgroundImage.file.url})`,
          backgroundSize: `
            ${backgroundImage.file?.details?.image?.width}px ${backgroundImage.file?.details?.image?.height}px
          `,
          backgroundRepeat: 'no-repeat',
        }),
        ...colorTheme('background-color', backgroundColor),
      }}
      className={classnames(styles.container, {
        [styles.revealed]: inView,
        [styles.containerNoTitle]: !title,
        [styles.containerBGImage]: backgroundImage,
      })}
    >
      <div className={styles.inner} ref={containerRef}>
        {label && <div className={styles.label}>{label}</div>}
        {title &&
          (isFirstElement ? (
            <h1 className={styles.title}>{title}</h1>
          ) : (
            <h2 className={styles.title}>{title}</h2>
          ))}

        {tagIntro && (
          <RichText
            className={styles.intro}
            content={tagIntro.json || tagIntro}
          />
        )}

        <div
          className={classnames(styles.content, {
            [styles.contentNoTitle]: !title,
          })}
        >
          {graphic && (
            <div
              className={classnames(styles.graphicContainer, {
                [styles.graphicRight]: alignment === 'Graphic Right',
              })}
            >
              <Media
                className={styles.graphic}
                fileUrl={graphic.file.url}
                localFile={graphic.fluid}
                imageFormat="fluid"
                type={graphic.file.contentType}
                autoplay={false}
                muted={false}
                loop={false}
              />
            </div>
          )}

          <div
            className={classnames(styles.text, {
              [styles.textWithTitle]: !!copyTitle,
              [styles.textWithoutGraphic]: !graphic,
              [styles.textWithBGImage]: backgroundImage,
            })}
          >
            {copyTitle && <h3 className={styles.copyTitle}>{copyTitle}</h3>}
            {copy && (
              <RichText
                content={copy.json || copy}
                className={classnames({ [styles.copyWithTitle]: !!copyTitle })}
              />
            )}
            {buttonText && (
              <SmartLink to={buttonUrl} className={styles.button}>
                {buttonText}
              </SmartLink>
            )}
            {secondButtonText && (
              <SmartLink to={secondButtonUrl} className={styles.secondButton}>
                {secondButtonText}
              </SmartLink>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const textAndGraphicFragment = graphql`
  fragment TextAndGraphicFragment on Node {
    ... on ContentfulTextAndGraphic {
      title
      label
      tagIntro: intro {
        json
      }
      graphic {
        file {
          url
          contentType
        }
        fluid(maxWidth: 540) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      copyTitle
      copy {
        json
      }
      alignment
      buttonText
      buttonUrl
      backgroundImage {
        file {
          url
          contentType
        }
        fluid(maxWidth: 540) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      secondButtonText
      secondButtonUrl
      backgroundColor
      sectionId
    }
  }
`;
