import React, { useState } from 'react';
import classnames from 'classnames';
import { graphql } from 'gatsby';
import styles from './teamMembers.module.scss';

/*
 *   Team member card
 */
function TeamMemberCard({
  index,
  name,
  photo,
  jobTitle,
  bio,
  subject,
  idOfTeamMemberShowingBio,
  setIdOfTeamMemberShowingBio,
}) {
  return (
    <article
      className={classnames(styles.teamMember, {
        [styles.teamMember__open]: idOfTeamMemberShowingBio === index,
      })}
      onClick={() => setIdOfTeamMemberShowingBio(index)}
    >
      <button
        onClick={(event) => {
          event.stopPropagation();
          setIdOfTeamMemberShowingBio(null);
        }}
      >
        &times;
      </button>
      <figure style={{ backgroundImage: `url(${photo.fluid.src})` }}></figure>
      <div className={styles.visibleNameAndDept}>
        <h3 className={styles.visible__name}>{name}</h3>
        <h4>{jobTitle}</h4>
        <h4>{subject}</h4>
      </div>
      <div
        style={{
          visibility: idOfTeamMemberShowingBio === index ? 'visible' : 'hidden',
        }}
      >
        <p className={styles.openBioName}>{name}</p>
        <p className={styles.openBioJobTitle}>{jobTitle}</p>
        <p className={styles.visible__bio}>{bio?.bio}</p>
        {/* <p className={styles.subject}>{subject?.subject}</p> */}
      </div>
    </article>
  );
}

export default function TeamMembers({ title, sectionId, teamMembers }) {
  const [idOfTeamMemberShowingBio, setIdOfTeamMemberShowingBio] =
    useState(null);

  const teamMemberEls = teamMembers.map((tm, index) => (
    <TeamMemberCard
      key={tm.id}
      index={index}
      name={tm.name}
      photo={tm.photo}
      jobTitle={tm.jobTitle}
      bio={tm.bio}
      idOfTeamMemberShowingBio={idOfTeamMemberShowingBio}
      setIdOfTeamMemberShowingBio={setIdOfTeamMemberShowingBio}
    />
  ));

  /*
   *   Return
   */
  return (
    <section id={sectionId} className={classnames(styles.container)}>
      <div className={styles.inner}>
        <h1 className={styles.title}>
          <span style={{ position: 'relative', textAlign: 'left' }}>
            {title}
          </span>
        </h1>
        <div
          className={classnames(styles.members, {
            [styles.teamMembers__open]: idOfTeamMemberShowingBio !== null,
          })}
        >
          {teamMemberEls}
        </div>
      </div>
    </section>
  );
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const pressReleasesFragment = graphql`
  fragment TeamMembersFragment on Node {
    ... on ContentfulTeamMembers {
      title
      sectionId
      teamMembers {
        id
        name
        jobTitle
        bio {
          bio
        }
        subject {
          subject
        }
        photo {
          fluid(maxWidth: 1100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;
