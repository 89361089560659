import React from 'react';
import { graphql } from 'gatsby';
import StateStandards from './stateStandards';
import FeaturedContent from './featuredContent';
import PartnerGrid from './partnerGrid';
import ProductComparison from './productComparison';
import PressHighlight from './pressHighlight';
import PressArticles from './pressArticles';
import FeatureTableGroup from './featureTableGroup';
import DropdownCards from './dropdownCards';

interface MultipurposeContainerProps {
  contentType: string;
}

export default function MultipurposeContainer(
  props: MultipurposeContainerProps
) {
  let BlockComponent: React.ComponentType<any> | null = null;

  switch (props.contentType) {
    case 'State Standards':
      BlockComponent = StateStandards;
      break;
    case 'Content Cards':
      BlockComponent = FeaturedContent;
      break;
    case 'Partners':
      BlockComponent = PartnerGrid;
      break;
    case 'Product Comparison':
      BlockComponent = ProductComparison;
      break;
    case 'Press Highlight':
      BlockComponent = PressHighlight;
      break;
    case 'Press Articles':
      BlockComponent = PressArticles;
      break;
    case 'Feature Table Group':
      BlockComponent = FeatureTableGroup;
      break;
    case 'Dropdown Cards':
      BlockComponent = DropdownCards;
      break;
    default:
      BlockComponent = FeaturedContent;
  }
  return <BlockComponent {...props} />;
}

export const multipurposeContainerFragment = graphql`
  fragment MultipurposeContainerFragment on Node {
    ... on ContentfulMultipurposeContainer {
      title
      description {
        json
      }
      contentType
      sectionId
      backgroundColor
      buttonText
      buttonUrl
      gridLayout
      gridLayoutColumns
      contentBlocks {
        ... on ContentfulPartner {
          id
          link
          name
          blurb {
            blurb
          }
          logo {
            title
            file {
              url
            }
          }
        }
        ... on ContentfulPressArticle {
          id
          title
          publication
          author
          date
          articleUrl
          excerpt {
            json
          }
          image {
            fluid(maxWidth: 350) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        ... on ContentfulStateStandard {
          id
          title
          leftColumn {
            json
          }
          rightColumn {
            json
          }
          sectionId
        }
        ... on ContentfulContentCard {
          id
          title
          label
          image {
            title
            file {
              url
            }
          }
          description {
            json
          }
          buttonText
          buttonUrl
        }
        ... on ContentfulFeatureTable {
          id
          title
          category
          description {
            json
          }
          column1LinkText
          column1LinkUrl
          column1Title
          column1TitleLink
          column1Icon {
            file {
              url
            }
          }
          column2LinkText
          column2LinkUrl
          column2Title
          column2TitleLink
          column2Icon {
            file {
              url
            }
          }
          column3LinkText
          column3LinkUrl
          column3Title
          column3TitleLink
          column3Icon {
            file {
              url
            }
          }
          column4LinkText
          column4LinkUrl
          column4Title
          column4TitleLink
          column4Icon {
            file {
              url
            }
          }
          highlightedColumn
          spreadsheet {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
