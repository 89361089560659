import React, { useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import Mousetrap from 'mousetrap';
import { useInView } from 'react-intersection-observer';
import forceLayout from 'helpers/forceLayout';
import colorTheme from 'helpers/colorTheme';
import useWindowSize from 'hooks/useWindowSize';
import LazyImage from 'components/lazyImage';
import Markdone from 'components/markdone';
import RichText from 'components/richText';
import SmartLink from './smartLink';
import styles from './partnerGrid.module.scss';

function CardDetail({ p, originRef, fromBottom }) {
  const windowSize = useWindowSize(250);
  const [isActive, setIsActive] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const rafRef = useRef(null);
  const detailsRef = useRef();
  const originBox = originRef.current.getBoundingClientRect();
  const originTop = window.pageYOffset + originBox.top;
  const onRight = originBox.left > document.body.clientWidth / 2;
  const originRight =
    document.body.clientWidth - originBox.left - originBox.width;
  const smallScreen = windowSize.width < 1001;

  const tOriginX = onRight ? 'right' : 'left';
  const tOriginY = fromBottom ? 'bottom' : 'top';
  const detHeight = originBox.height * 2 + 30;
  const detWidth = originBox.width * 2 + 30;
  let padding = 64;

  if (windowSize.width < 1001) padding = 40;
  if (windowSize.width < 501) padding = 30;
  if (windowSize.width < 381) padding = 0;

  const containerStyle = smallScreen
    ? {
        top: fromBottom
          ? `${originTop - (detHeight - originBox.height)}px`
          : `${originTop}px`,
        left: `${padding}px`,
        height: 'auto',
        width: `calc(100% - ${padding * 2}px)`,
        transform: isActive ? 'none' : `scale(${originBox.width / detWidth})`,
        transformOrigin: `${tOriginY} ${tOriginX}`,
        transition: 'transform .5s var(--ease)',
      }
    : {
        top: fromBottom
          ? `${originTop - (detHeight - originBox.height)}px`
          : `${originTop}px`,
        left: !onRight ? `${originBox.left}px` : null,
        right: onRight ? `${originRight}px` : null,
        height: `${detHeight}px`,
        width: `${detWidth}px`,
        transform: isActive ? 'none' : `scale(${originBox.width / detWidth})`,
        transformOrigin: `${tOriginY} ${tOriginX}`,
        transition: 'transform .5s var(--ease)',
      };

  useEffect(() => {
    setIsActive(true);
  }, []);

  useEffect(() => {
    if (isActive) {
      forceLayout();
      rafRef.current = requestAnimationFrame(() => setIsVisible(true));
    }

    return () => cancelAnimationFrame(rafRef.current);
  }, [isActive, isVisible]);

  return createPortal(
    <div
      ref={detailsRef}
      className={classnames(styles.partner__details, {
        [styles.active]: isActive,
        [styles.visible]: isVisible,
      })}
      style={containerStyle}
    >
      <div className={styles.partner__details_logo}>
        {p.logo && (
          <LazyImage
            className={styles.partner__details_logo_image}
            src={`${p.logo.file.url}?w=580`}
            alt={p.logo.title}
          />
        )}
      </div>
      <div className={styles.partner__details_content}>
        <h3 className={styles.partner__name}>{p.name}</h3>
        <Markdone markdown={p.blurb.blurb} className={styles.partner__blurb} />
        {p.link && (
          <SmartLink to={p.link} className={styles.partner__link}>
            {p.logo.title}
          </SmartLink>
        )}
      </div>
    </div>,
    document.body
  );
}

export default function PartnerGrid({
  title,
  description,
  contentBlocks,
  sectionId,
  backgroundColor,
}) {
  const [containerRef, inView] = useInView({ triggerOnce: true });
  const partnersRef = useRef(null);
  const partners = contentBlocks;

  /*
   *   Partner Els
   */
  function PartnerEl({ p, i }) {
    const [isActive, setIsActive] = useState(false);
    const ref = useRef(null);
    function close() {
      setIsActive(false);
      Mousetrap.unbind(['esc', 'tab', 'shift+tab']);
    }

    useEffect(() => {
      if (isActive) {
        document.addEventListener('click', close);
        Mousetrap.bind(['esc', 'tab', 'shift+tab'], close);
      }

      return () => {
        document.removeEventListener('click', close);
        Mousetrap.unbind(['esc', 'tab', 'shift+tab']);
      };
    }, [isActive]);

    return (
      <button
        ref={ref}
        onClick={() => p.blurb && setIsActive(true)}
        className={classnames(styles.partner, {
          [styles.active]: isActive,
          [styles.no_blurb]: !p.blurb,
        })}
      >
        {p.logo && (
          <img
            className={styles.partner__logo}
            src={`${p.logo.file.url}?w=580`}
            alt={p.logo.title}
            loading="lazy"
          />
        )}
        <h3 className={styles.partner__name}>
          {isActive ? <SmartLink to={p.link}>{p.ctaButton}</SmartLink> : p.name}
        </h3>

        {isActive && (
          <CardDetail
            originRef={ref}
            p={p}
            fromBottom={i > partners.length - 4}
          />
        )}
      </button>
    );
  }

  const partnerEls = partners.map((p, i) => (
    <PartnerEl key={p.id} p={p} i={i} />
  ));
  /*
   *   Return
   */
  return (
    <section
      id={sectionId}
      ref={containerRef}
      style={colorTheme('background-color', backgroundColor)}
      className={classnames(styles.container, {
        [styles.revealed]: inView,
        [styles.two_up]: partnerEls.length < 3 || partnerEls.length === 4,
      })}
    >
      <div className={styles.inner}>
        <h2 className={styles.title}>{title}</h2>
        {description && (
          <RichText className={styles.description} content={description.json} />
        )}
        <div ref={partnersRef} className={styles.partners}>
          {partnerEls}
        </div>
      </div>
    </section>
  );
}
