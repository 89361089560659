import React, { useState, useLayoutEffect } from 'react';
import classnames from 'classnames';
import Img from 'gatsby-image';
import forceLayout from 'helpers/forceLayout';
import styles from './exampleContentCard.module.scss';

export default function ExampleContentCard({
  title,
  label,
  image,
  icons,
  url,
  className,
}) {
  const [isVisible, setIsVisible] = useState(false);

  useLayoutEffect(() => {
    forceLayout();
    setIsVisible(true);
  }, []);

  const InnerElementTag = url ? 'a' : 'div';

  const iconEls =
    icons?.map((icon) => (
      <img key={icon.id} src={icon.file.url} alt={icons.title} loading="lazy" />
    )) || null;

  return (
    <article
      className={classnames(styles.card, className, {
        [styles.is_visible]: isVisible,
      })}
    >
      <InnerElementTag
        href={url}
        target="_blank"
        className={styles.card__inner}
      >
        {image && (
          <Img
            className={styles.card__image}
            height="201"
            fluid={image.fluid}
            alt={image.title}
          />
        )}
        <div className={styles.card__text}>
          <header className={styles.card__header}>
            <div className={styles.card__label}>{label}</div>
          </header>
          <h3 className={styles.card__title}>{title}</h3>
          {iconEls && <div className={styles.card__icons}>{iconEls}</div>}
        </div>
      </InnerElementTag>
    </article>
  );
}
