import React from 'react';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';
import colorTheme from 'helpers/colorTheme';
import RichText from 'components/richText';
import SmartLink from 'components/smartLink';
import styles from './featuredContent.module.scss';

/*
 *   Article card
 */
function ArticleCard({
  label,
  image,
  title,
  json,
  link,
  linkText,
  borderColor,
  contentType,
}) {
  const cardStyle = { ...colorTheme('border-color', borderColor) };
  const articleLinkStyle = { color: colorTheme('blue') };
  return (
    <article
      style={cardStyle}
      className={classnames(styles.article, {
        [styles.no_image]: !image,
        [styles.custom_article]: contentType === 'RTE cards',
      })}
    >
      {image && (
        <div
          className={styles.article_image}
          style={{ backgroundImage: `url(${image}?w=300)` }}
          alt=""
        />
      )}
      <div
        className={classnames(styles.article_content, {
          [styles.no_json]: !json || !link,
        })}
      >
        {label && <div className={styles.article_type}>{label}</div>}
        {title && <h3 className={styles.article_title}>{title}</h3>}
        {json && <RichText className={styles.article_json} content={json} />}
        {link && (
          <SmartLink
            className={styles.article_link}
            to={link}
            aria-label={linkText}
            style={articleLinkStyle}
          >
            {linkText}
          </SmartLink>
        )}
      </div>
    </article>
  );
}

/*
 *   Container component
 */
export default function FeaturedContent({
  title,
  description,
  contentBlocks,
  sectionId,
  backgroundColor,
  isFirstElement,
  contentType,
  gridLayout,
  gridLayoutColumns,
}) {
  const [containerRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const cardEls = contentBlocks?.map(
    ({ id, title, label, image, description, buttonText, buttonUrl }) => {
      return (
        <ArticleCard
          key={id}
          title={title}
          label={label}
          image={image ? image.file.url : null}
          json={description ? description.json || description : null}
          link={buttonUrl}
          linkText={buttonText}
          aria-label={buttonText}
          borderColor={backgroundColor}
          contentType={contentType}
        />
      );
    }
  );

  return (
    <section
      id={sectionId}
      ref={containerRef}
      style={colorTheme('background-color', backgroundColor)}
      className={classnames(styles.container, {
        [styles.revealed]: inView,
        [styles.featuredContentContainer]: isFirstElement,
      })}
    >
      <div className={styles.inner}>
        {title && <h2 className={styles.title}>{title}</h2>}
        {description && (
          <RichText
            className={styles.description}
            content={description.json || description}
          />
        )}

        <div
          className={classnames(styles.articles, {
            [styles[`grid_${gridLayoutColumns}`]]: gridLayout,
            [styles.grid_layout]: gridLayout,
            [styles.three_up]: !gridLayout && contentBlocks?.length === 3,
            [styles.two_up]: !gridLayout && contentBlocks?.length === 2,
            [styles.one_up]: !gridLayout && contentBlocks?.length === 1,
            [styles.no_padding]: !title && !description,
            [styles.custom_article_container]: contentType === 'RTE cards',
          })}
        >
          {cardEls}
        </div>
      </div>
    </section>
  );
}
