import React from 'react';
import classnames from 'classnames';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import useWindowSize from 'hooks/useWindowSize';
import RichText from 'components/richText';
import styles from './pillars.module.scss';

function Pillar({ icon, title, color, text, extraText }) {
  const [containerRef, inView] = useInView({ triggerOnce: true });

  const extraTextEls = extraText
    ? extraText.map((et) => <RichText key={et.id} content={et.rte.json} />)
    : null;

  return (
    <div
      ref={containerRef}
      className={classnames(styles.pillar, styles[color], {
        [styles.revealed]: inView,
      })}
    >
      <div className={styles.pillar__header}>
        {icon && <img src={icon.file.url} alt={icon.title} loading="lazy" />}
        <h2 className={styles.pillar__title}>{title}</h2>
      </div>
      {text && <RichText className={styles.pillar__text} content={text.json} />}
      {extraText && <div className={styles.pillar__extra}>{extraTextEls}</div>}
    </div>
  );
}

export default function Pillars({ title, pillars, sectionId, isFirstElement }) {
  const [containerRef, inView] = useInView({ triggerOnce: true });
  const windowSize = useWindowSize();

  const InnerElementTag = isFirstElement ? 'h1' : 'h2';

  const pillarElsLeft =
    windowSize.width > 750
      ? pillars
          .filter((p, i) => !(i & 1))
          .map(({ id, icon, title, color, text, extraText }) => {
            return (
              <Pillar
                key={id}
                icon={icon}
                title={title}
                color={color}
                text={text}
                extraText={extraText}
              />
            );
          })
      : pillars.map(({ id, icon, title, color, text, extraText }) => {
          return (
            <Pillar
              key={id}
              icon={icon}
              title={title}
              color={color}
              text={text}
              extraText={extraText}
            />
          );
        });

  const pillarElsRight =
    windowSize.width > 750
      ? pillars
          .filter((p, i) => i & 1)
          .map(({ id, icon, title, color, text, extraText }) => {
            return (
              <Pillar
                key={id}
                icon={icon}
                title={title}
                color={color}
                text={text}
                extraText={extraText}
              />
            );
          })
      : null;

  /*
   *   Return
   */
  return (
    <section
      id={sectionId}
      ref={containerRef}
      className={classnames(styles.container, { [styles.revealed]: inView })}
    >
      <div className={styles.inner}>
        <InnerElementTag className={styles.title}>{title}</InnerElementTag>
        <div className={styles.pillars}>
          <div className={styles.pillars__left}>{pillarElsLeft}</div>
          {pillarElsRight && (
            <div className={styles.pillars__right}>{pillarElsRight}</div>
          )}
        </div>
      </div>
    </section>
  );
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const pillarsFragment = graphql`
  fragment PillarsFragment on Node {
    ... on ContentfulPillars {
      title
      sectionId
      pillars {
        id
        title
        text {
          json
        }
        extraText {
          id
          rte {
            json
          }
        }
        icon {
          title
          file {
            url
          }
        }
        color
      }
    }
  }
`;
