import { createPortal } from 'react-dom';
import React, {
  useState,
  useEffect,
  useRef,
  ReactNode,
  CSSProperties,
} from 'react';
import classnames from 'classnames';
import Mousetrap from 'mousetrap';
import removeBr from '../helpers/removeBr';
import forceLayout from '../helpers/forceLayout';
import SmartLink from '../components/smartLink';
import styles from './choosy.module.scss';

function Dropdown({
  children,
  originRef,
  label,
  onClick,
}: {
  children: ReactNode;
  originRef: any;
  label?: string;
  onClick: () => void;
}) {
  const originBox = originRef.current
    ? originRef.current.getBoundingClientRect()
    : {};
  const [isVisible, setIsVisible] = useState(false);
  const rafRef = useRef(null);
  const buttonRef = useRef(null);
  const originTop = window.pageYOffset + originBox.top;

  const dropDownStyles = {
    top: `${originTop}px`,
    left: `${originBox.left}px`,
    width: `${originBox.width}px`,
  };

  useEffect(() => {
    Mousetrap.bind('esc', onClick);
    forceLayout();
    rafRef.current = requestAnimationFrame(() => {
      setIsVisible(true);
      buttonRef.current.focus();
    });

    return () => {
      Mousetrap.unbind('esc');
      cancelAnimationFrame(rafRef.current);
    };
  });

  return createPortal(
    <>
      <div
        className={classnames(styles.mask, { [styles.visible]: isVisible })}
        onClick={onClick}
      />
      <div
        style={dropDownStyles}
        className={classnames(styles.dropdown, { [styles.visible]: isVisible })}
        onClick={onClick}
      >
        <button ref={buttonRef} className={styles.dropdown__button}>
          {removeBr(label)}
        </button>
        {children}
      </div>
    </>,
    document.body
  );
}

export function OptionGroup({
  title,
  children,
  className,
}: {
  title?: string;
  children: ReactNode;
  className?: string;
}) {
  return (
    <div className={classnames(styles.option_group, className)}>
      {title && <div className={styles.option_group__title}>{title}</div>}
      {children}
    </div>
  );
}

export function Option({
  children,
  onClick,
  to,
  className,
  style,
}: {
  to?: string;
  children: ReactNode;
  onClick: () => void;
  className?: string;
  style?: CSSProperties;
}) {
  return to ? (
    <SmartLink
      className={classnames(styles.option, className)}
      style={style}
      to={to}
    >
      {children}
    </SmartLink>
  ) : (
    <button
      className={classnames(styles.option, className)}
      style={style}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export function Choosy({
  label,
  children,
  className,
}: {
  label?: string;
  children: ReactNode;
  className?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);

  function close() {
    setIsOpen(false);
    buttonRef.current.focus();
  }

  return (
    <>
      <button
        ref={buttonRef}
        className={classnames(styles.button, className)}
        onClick={() => setIsOpen(true)}
      >
        {removeBr(label)}
      </button>
      {isOpen && (
        <Dropdown
          label={label}
          originRef={buttonRef}
          onClick={close}
          aria-label="close"
        >
          {children}
        </Dropdown>
      )}
    </>
  );
}
