import React from 'react';
import classnames from 'classnames';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import useVidyard from 'hooks/useVidyard';
import styles from './videoSection.module.scss';

export default function VideoSection({
  vidyardEmbed,
  backgroundColor,
  sectionId,
}) {
  useVidyard();
  const [containerRef, inView] = useInView({ triggerOnce: true });

  /*
   *   Return
   */
  return (
    <section
      id={sectionId}
      ref={containerRef}
      className={classnames(styles.container, styles[backgroundColor], {
        [styles.revealed]: inView,
      })}
    >
      <div className={styles.inner}>
        {vidyardEmbed && (
          <div
            className={styles.vidyard}
            dangerouslySetInnerHTML={{ __html: vidyardEmbed.vidyardEmbed }}
          />
        )}
      </div>
    </section>
  );
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const videoSectionFragment = graphql`
  fragment VideoSectionFragment on Node {
    ... on ContentfulVideoSection {
      title
      vidyardEmbed {
        vidyardEmbed
      }
      backgroundColor
      sectionId
    }
  }
`;
