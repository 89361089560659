import React, { useState, useRef, useEffect } from 'react';
import { useSpring, animated, interpolate } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { useInView } from 'react-intersection-observer';
import useWindowSize from 'hooks/useWindowSize';

export default function Roundabout({
  children,
  maxSlideWidth,
  onSlideChange,
  currentSlide,
  className,
  autoplay,
  wrapAround,
  container,
}) {
  const windowSize = useWindowSize(500);
  const [containerRef, inView] = useInView({ threshold: 0.3 });
  const [isPaused, setIsPaused] = useState(false);
  const isLoaded = useRef(false);
  const slideIndex = useRef(1);
  const intervalRef = useRef(null);
  const [{ x }, set] = useSpring(() => ({ x: 0 }));
  const anchorSlideRef = useRef(null);
  const childArray = wrapAround
    ? [children[children.length - 1], ...children, children[0]]
    : children;

  const containerWidth = container ? container.offsetWidth : windowSize.width;

  /*
   *   Calculate x offset
   */
  function calculateOffset(max) {
    const slideWidth = anchorSlideRef?.current?.offsetWidth || 0;

    if (max) {
      return (
        containerWidth / 2 -
        (childArray.length - 1) * slideWidth -
        slideWidth / 2
      );
    }

    return (
      containerWidth / 2 - slideIndex.current * slideWidth - slideWidth / 2
    );
  }

  /*
   *   Set current slide
   */
  function setSlide(index) {
    slideIndex.current = index;
    onSlideChange(index);
  }

  /*
   *   Increment index by 1
   */
  function incrementIndex() {
    if (slideIndex.current >= childArray.length - 1) {
      slideIndex.current = childArray.length - 1;
    } else {
      slideIndex.current += 1;
    }
  }

  /*
   *   Decrement index  1
   */
  function decrementIndex() {
    if (slideIndex.current <= 0) {
      slideIndex.current = 0;
    } else {
      slideIndex.current -= 1;
    }
  }

  /*
   *   Advance
   */
  function advance() {
    if (wrapAround) {
      if (slideIndex.current >= children.length) {
        onSlideChange(0);
      } else {
        onSlideChange(slideIndex.current);
      }
    } else {
      if (slideIndex.current >= childArray.length - 1) {
        slideIndex.current = 0;
      } else {
        incrementIndex();
      }
      set(() => ({ x: calculateOffset() }));
      onSlideChange(slideIndex.current);
    }
  }

  /*
   *   Bind gestures
   */
  const touchBind = useDrag(({ down, movement: [mx], cancel, canceled }) => {
    if (canceled || wrapAround) return;
    const shouldTransition =
      containerWidth > 1000 ? Math.abs(mx) > 50 : Math.abs(mx) > 50;
    if (shouldTransition) {
      cancel();
      if (mx < 1) {
        incrementIndex();
      } else {
        decrementIndex();
      }
      set({ x: calculateOffset() });
      onSlideChange(slideIndex.current);
    } else {
      set({ x: down ? calculateOffset() + mx : calculateOffset() });
    }
  });

  /*
   *   Use effect
   */
  useEffect(() => {
    if (!anchorSlideRef.current) return;

    if (wrapAround) {
      let actualSlide = currentSlide + 1;
      const wrapAroundFront =
        slideIndex.current - 1 >= children.length - 1 && currentSlide === 0;
      const wrapAroundBack = slideIndex.current - 1 === 0 && currentSlide > 1;

      slideIndex.current = actualSlide;

      let springConfig = {
        x: calculateOffset(),
        reset: false,
        immediate: !isLoaded.current,
      };

      if (wrapAroundFront) {
        springConfig = {
          from: { x: 0 },
          to: { x: calculateOffset() },
          reset: true,
          immediate: !isLoaded.current,
        };
      }

      if (wrapAroundBack) {
        springConfig = {
          from: { x: calculateOffset(true) },
          to: { x: calculateOffset() },
          reset: true,
          immediate: !isLoaded.current,
        };
      }

      set(springConfig);
    } else {
      slideIndex.current = currentSlide;
      set({ x: calculateOffset() });
    }
  });

  useEffect(() => {
    if (autoplay) {
      intervalRef.current = setInterval(() => {
        if (inView && !isPaused) advance();
      }, autoplay);
    }
    isLoaded.current = true;

    return () => clearInterval(intervalRef.current);
  }, [inView, isPaused]); // eslint-disable-line

  /*
   *   Wrap children
   */
  const slideEls = childArray.map((child, i) => {
    const refAttr = i === 0 ? { ref: anchorSlideRef } : null;
    let key = child.key;
    if (i === 0) key = child.key + '-first';
    if (i === childArray.length - 1) key = child.key + '-last';

    return (
      <div
        {...refAttr}
        key={key}
        onClick={() => {
          if (wrapAround) {
            setSlide(i - 1);
          } else {
            setSlide(i);
          }
        }}
        style={{ maxWidth: maxSlideWidth, display: 'flex', flexShrink: 0 }}
      >
        {child}
      </div>
    );
  });

  /*
   *   Render
   */
  return (
    <div
      ref={containerRef}
      onMouseOver={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
      className={className}
      style={{ overflow: 'hidden' }}
    >
      <animated.div
        {...touchBind()}
        style={{
          transform: interpolate(x, (x) => `translate3d(${x}px,0,0)`),
          display: 'flex',
          userSelect: 'none',
        }}
      >
        {slideEls}
      </animated.div>
    </div>
  );
}
