import React, { useState, FC, CSSProperties } from 'react';
import { useInView } from 'react-intersection-observer';

interface LazyImageProps {
  src: string;
  className?: string;
  title?: string;
  height?: number;
  width?: number;
  style?: CSSProperties;
  onLoad?: () => void;
  alt: string;
}

const LazyImage: FC<LazyImageProps> = ({
  src,
  className,
  title,
  height,
  width,
  style,
  onLoad,
}) => {
  const [containerRef, inView] = useInView({ triggerOnce: true });
  const [isVisible, setIsVisible] = useState(false);

  function handleImageLoad() {
    requestAnimationFrame(() => setIsVisible(true));
    if (onLoad) onLoad();
  }

  const imageStyle: CSSProperties = {
    ...style,
    opacity: isVisible && inView ? 1 : 0,
    transition: 'all .6s var(--ease)',
  };

  return (
    <img
      style={imageStyle}
      ref={containerRef}
      src={
        inView
          ? src
          : 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='
      }
      alt={title || ''}
      height={height}
      width={width}
      className={className}
      onLoad={handleImageLoad}
      loading="lazy"
    />
  );
};

export default LazyImage;
