import React, { useState } from 'react';
import classnames from 'classnames';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { useInView } from 'react-intersection-observer';
import colorTheme from 'helpers/colorTheme';
import SmartLink from 'components/smartLink';
import RichText from 'components/richText';
import Roundabout from './roundabout';
import TextWithBr from 'components/textWithBr';
import { ReactComponent as LeftCaretIcon } from 'images/icon-caret-left.svg';
import { ReactComponent as RightCaretIcon } from 'images/icon-caret-right.svg';
import styles from './testimonialCarousel.module.scss';

export default function TestimonialCarousel({
  title,
  text,
  buttonText,
  buttonUrl,
  quotes,
  backgroundColor,
  layout,
  sectionId,
  isFirstElement,
}) {
  const [containerRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const [currentSlide, setCurrentSlide] = useState(1);

  /*
   *   Quote els
   */
  const quoteEls = quotes.map((q, i) => (
    <blockquote
      key={q.id}
      className={classnames(styles.quote, {
        [styles.is_current]: i === currentSlide,
      })}
    >
      {q.photo && (
        <Img
          className={styles.quote__image}
          fixed={q.photo.fixed}
          alt={q.photo.title}
        />
      )}
      <div className={styles.quote__content}>
        {q.name && <div className={styles.quote__author}>{q.name}</div>}
        {q.quote && (
          <RichText className={styles.quote__text} content={q.quote.json} />
        )}
      </div>
    </blockquote>
  ));

  /*
   *   Pagination els
   */
  const paginationEls = quotes.map((q, i) => (
    <button
      tabIndex={-1}
      key={q.id}
      aria-label={`Go to slide ${i}`}
      className={classnames(styles.pagination_button, {
        [styles.current]: i === currentSlide,
      })}
      onClick={() => setCurrentSlide(i)}
    >
      Go to slide {i}
    </button>
  ));

  function advance() {
    const next =
      currentSlide >= quotes.length - 1 ? currentSlide : currentSlide + 1;
    setCurrentSlide(next);
  }

  function retreat() {
    const prev = currentSlide === 0 ? currentSlide : currentSlide - 1;
    setCurrentSlide(prev);
  }

  /*
   *   Return
   */
  return (
    <section
      id={sectionId}
      ref={containerRef}
      style={
        layout.replace(/\s+/g, '') === 'Layout1'
          ? colorTheme('background-color', backgroundColor)
          : null
      }
      className={classnames(
        styles.container,
        styles[layout.replace(/\s+/g, '')],
        {
          [styles.revealed]: inView,
        }
      )}
    >
      <div
        style={colorTheme('background-color', backgroundColor)}
        className={styles.background}
      ></div>
      <div className={styles.inner}>
        {title && layout === 'Layout 2' && (
          <TextWithBr
            className={styles.title}
            tag={isFirstElement ? 'h1' : 'h2'}
            content={title}
          />
        )}
        <Roundabout
          className={styles.carousel}
          maxSlideWidth="850px"
          currentSlide={currentSlide}
          onSlideChange={(index) => setCurrentSlide(index)}
          autoplay={4000}
        >
          {quoteEls}
        </Roundabout>
        {layout === 'Layout 2' && (
          <div className={styles.pagination}>{paginationEls}</div>
        )}
        {layout === 'Layout 2' && (
          <div className={styles.arrows}>
            <button
              className={styles.arrow}
              onClick={retreat}
              aria-label="Previous slide"
            >
              <LeftCaretIcon />
            </button>
            <button
              className={styles.arrow}
              onClick={advance}
              aria-label="Next slide"
            >
              <RightCaretIcon />
            </button>
          </div>
        )}
        {title && layout === 'Layout 1' && (
          <TextWithBr className={styles.title} tag="h1" content={title} />
        )}
        {text && layout === 'Layout 1' && (
          <RichText className={styles.text} content={text.json} />
        )}
        {buttonText && layout === 'Layout 1' && (
          <SmartLink className={styles.button} to={buttonUrl}>
            {buttonText}
          </SmartLink>
        )}
      </div>
    </section>
  );
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const testimonialCarouselFragment = graphql`
  fragment TestimonialCarouselFragment on Node {
    ... on ContentfulTestimonialCarousel {
      sectionId
      title
      text {
        json
      }
      buttonText
      buttonUrl
      backgroundColor
      layout
      quotes {
        id
        name
        location
        quote {
          json
        }
        photo {
          title
          fixed(width: 120) {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
        }
      }
    }
  }
`;
