import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import forceLayout from 'helpers/forceLayout';
import colorTheme from 'helpers/colorTheme';
import Media from 'components/media';
import RichText from 'components/richText';
import SmartLink from 'components/smartLink';
import TextWithBr from 'components/textWithBr';
import { ReactComponent as LeftCaretIcon } from 'images/icon-caret-left.svg';
import { ReactComponent as RightCaretIcon } from 'images/icon-caret-right.svg';
import styles from 'components/slideDeck.module.scss';

export default function SlideDeck({
  title,
  backgroundColor,
  slides,
  sectionId,
}) {
  const [containerRef, inView] = useInView({ triggerOnce: true });
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [slideIsVisible, setSlideIsVisible] = useState(false);
  const currentSlide = slides && slides[currentSlideIndex];
  const rafRef = useRef(null);

  /*
   *   Advance
   */
  function advance() {
    const nextIndex =
      currentSlideIndex < slides.length - 1 ? currentSlideIndex + 1 : 0;

    setSlideIsVisible(false);
    setCurrentSlideIndex(nextIndex);
  }

  /*
   *   Retreat
   */

  function retreat() {
    const prev =
      currentSlideIndex <= 0 ? slides.length - 1 : currentSlideIndex - 1;

    setSlideIsVisible(true);
    setCurrentSlideIndex(prev);
  }

  /*
   *   Pagination els
   */
  const paginationEls = slides.map((s, i) => (
    <button
      aria-label={`Go to slide ${i}`}
      key={s.id}
      className={classnames(styles.pagination_button, {
        [styles.current]: i === currentSlideIndex,
      })}
      onClick={retreat}
    >
      Go to slide {i}
    </button>
  ));

  /*
   *   Use Effect
   */
  useEffect(() => {
    if (!slideIsVisible) {
      forceLayout();
      rafRef.current = requestAnimationFrame(() => setSlideIsVisible(true));
    }

    return () => cancelAnimationFrame(rafRef.current);
  }, [slideIsVisible]);

  /*
   *   Return
   */
  return (
    <section
      aria-label="Carousel"
      id={sectionId}
      ref={containerRef}
      style={colorTheme('background-color', backgroundColor)}
      className={classnames(styles.container, {
        [styles.revealed]: inView,
        [styles.graphic_right]: currentSlide.alignment === 'Graphic Right',
      })}
    >
      <div className={styles.inner}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.controls}>
          <button
            aria-label="Previous slide"
            className={styles.control}
            onClick={retreat}
          >
            <LeftCaretIcon />
          </button>
          <button
            aria-label="Next slide"
            className={styles.control}
            onClick={advance}
          >
            <RightCaretIcon />
          </button>
        </div>

        <div
          className={classnames(styles.slide, {
            [styles.visible]: slideIsVisible,
            'first-slide-1': currentSlideIndex === 0,
          })}
        >
          <div className={styles.slide__content}>
            {currentSlide.alignment !== 'Graphic Right' &&
              currentSlide.graphic && (
                <Media
                  className={styles.slide__graphic}
                  fileUrl={currentSlide.graphic.file.url}
                  localFile={currentSlide.graphic.fluid}
                  imageFormat="fluid"
                  type={currentSlide.graphic.file.contentType}
                />
              )}
            <div className={styles.slide__text}>
              {currentSlide.title && (
                <TextWithBr
                  className={classnames(styles.slide__title, {
                    'first-slide': currentSlideIndex === 0,
                  })}
                  content={currentSlide.title}
                  tag="h2"
                />
              )}
              {currentSlide.intro && (
                <RichText
                  className={styles.slide__intro}
                  content={currentSlide.intro.json}
                />
              )}
              {currentSlide.buttonText && (
                <SmartLink
                  aria-label={currentSlide.buttonText}
                  to={currentSlide.buttonUrl}
                  className={styles.slide__button}
                >
                  {currentSlide.buttonText}
                </SmartLink>
              )}
            </div>
            {currentSlide.alignment === 'Graphic Right' &&
              currentSlide.graphic && (
                <Media
                  className={styles.slide__graphic}
                  fileUrl={currentSlide.graphic.file.url}
                  localFile={currentSlide.graphic.fluid}
                  imageFormat="fluid"
                  type={currentSlide.graphic.file.contentType}
                />
              )}
          </div>
          {currentSlide.backgroundImage && (
            <div
              className={styles.slide__background}
              style={{
                backgroundImage: `url(${currentSlide.backgroundImage.file.url})`,
              }}
            />
          )}
        </div>
        <div className={styles.pagination}>{paginationEls}</div>
      </div>
    </section>
  );
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const slideDeckFragment = graphql`
  fragment SlideDeckFragment on Node {
    ... on ContentfulSlideDeck {
      title
      sectionId
      slides {
        id
        title
        intro {
          json
        }
        buttonText
        buttonUrl
        alignment
        graphic {
          file {
            url
            contentType
          }
          fluid(maxWidth: 540) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        backgroundImage {
          file {
            url
          }
        }
      }
      backgroundColor
    }
  }
`;
