import React from 'react';
import SmartLink from './smartLink';
import IconCheckBlue from '../images/icon-blue-check.svg';
import styles from './productComparison.module.scss';

const HARCODED_FORMATIVE_FEATURE =
  'Customizable student activities with real-time insights via Formative';

const ProductComparisonCard = ({ product }) => {
  const {
    title,
    subtitle,
    buttonText,
    buttonUrl,
    openInNewTab,
    includedText,
    features,
    isPreferred,
    colorPreferred,
  } = product;

  return (
    <div className={styles.cardContainer}>
      {isPreferred && (
        <div
          className={styles.isPreferred}
          style={{ background: colorPreferred }}
        >
          PREFERRED
        </div>
      )}
      <div
        className={styles.card}
        style={{ backgroundColor: isPreferred ? '#E7F1FF' : '#FFF' }}
      >
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.subtitle}>{subtitle}</p>
        <SmartLink
          className={styles.button}
          to={buttonUrl}
          target={openInNewTab ? '_blank' : '_self'}
        >
          {buttonText}
        </SmartLink>
        <div className={styles.features}>
          {includedText && (
            <p className={styles.includedText}> {includedText}</p>
          )}
          {features.map((feature) => (
            <div className={styles.feature}>
              <div className={styles.icon}>
                <img src={IconCheckBlue} alt={'Icon check blue'} />
              </div>
              {feature != HARCODED_FORMATIVE_FEATURE ? (
                <p>{feature}</p>
              ) : (
                <p>
                  Customizable student activities with real-time insights via{' '}
                  <SmartLink
                    to="https://newsela.com/about/products/formative/"
                    target="_blank"
                  >
                    Formative
                  </SmartLink>
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductComparisonCard;
