import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { FluidObject } from 'gatsby-image';
import { useInView } from 'react-intersection-observer';
import formatDate from '../helpers/formatDate';
import RichText from './richText';
import SmartLink from './smartLink';
import styles from './pressArticles.module.scss';

interface PressArticlesProps {
  title: string;
  subtitle: {
    json: any;
  };
  sectionId: string;
}

interface ArticleProps {
  id: string;
  title: string;
  image: {
    fluid: FluidObject;
  };
  publication: string;
  author: string;
  excerpt: {
    json: any;
  };
  date: string;
  url: string;
}

interface ArticleCardProps extends Omit<ArticleProps, 'id'> {
  key: string;
}

interface ArticlesQueryResult {
  allContentfulPressArticle: {
    nodes: ArticleProps[];
  };
}

export default function PressArticles({
  title,
  subtitle,
  sectionId,
}: PressArticlesProps) {
  // Query main nav items
  const articlesData: ArticlesQueryResult = useStaticQuery(graphql`
    query articlesQuery {
      allContentfulPressArticle(sort: { fields: date, order: DESC }) {
        nodes {
          ... on Node {
            ... on ContentfulPressArticle {
              id
              title
              publication
              author
              date
              url: articleUrl
              excerpt {
                json
              }
              image {
                fluid(maxWidth: 350) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  `);

  const [containerRef, inView] = useInView({ triggerOnce: true });
  const [visibleArticles, setVisibleArticles] = useState<number>(3);

  // Load more articles
  function loadMore() {
    setVisibleArticles(visibleArticles + 2);
  }

  function ArticleCard({
    title,
    image,
    publication,
    author,
    excerpt,
    date,
    url,
  }: ArticleCardProps) {
    const formattedDate = formatDate(date);

    return (
      <article className={styles.article}>
        {image && <Img className={styles.article__image} fluid={image.fluid} />}
        <div className={styles.article__text}>
          <div className={styles.article__publication}>{publication}</div>
          <div className={styles.article__author}>{author}</div>
          <h2 className={styles.article__title}>{title}</h2>
          {excerpt && (
            <RichText
              className={styles.article__excerpt}
              content={excerpt.json}
            />
          )}
          <footer className={styles.article__footer}>
            <SmartLink to={url} className={styles.article__link}>
              Read more
            </SmartLink>
            <div className={styles.article__date}>{formattedDate}</div>
          </footer>
        </div>
      </article>
    );
  }

  const articleEls = useMemo(
    () =>
      articlesData?.allContentfulPressArticle?.nodes
        ?.filter((a, i) => i < visibleArticles)
        ?.map((a) => (
          <ArticleCard
            key={a.id}
            title={a.title}
            image={a.image}
            publication={a.publication}
            author={a.author}
            excerpt={a.excerpt}
            date={a.date}
            url={a.url}
          />
        )) || null,
    [articlesData.allContentfulPressArticle.nodes, visibleArticles]
  );

  return (
    <section
      id={sectionId}
      ref={containerRef}
      className={classnames(styles.container, { [styles.revealed]: inView })}
    >
      <div className={styles.inner}>
        {title && <h1 className={styles.title}>{title}</h1>}
        {subtitle && (
          <RichText className={styles.subtitle} content={subtitle.json} />
        )}
        <div className={styles.articles}>{articleEls}</div>
        {visibleArticles <
          articlesData.allContentfulPressArticle.nodes.length && (
          <button className={styles.button} onClick={loadMore}>
            View more
          </button>
        )}
      </div>
    </section>
  );
}
