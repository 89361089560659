import React, { useState, useEffect, useRef, cloneElement } from 'react';
import chunk from 'lodash/chunk';
import groupBy from 'lodash/groupBy';
import some from 'lodash/some';
import classnames from 'classnames';
import Mousetrap from 'mousetrap';
import { useTransition, animated } from 'react-spring';
import useWindowSize from 'hooks/useWindowSize';
import SmartLink from 'components/smartLink';
import { ReactComponent as CaretIcon } from 'images/icon-caret-down-alt.svg';
import { ReactComponent as RightCaretIcon } from 'images/icon-caret-right.svg';
import styles from './mainNav.module.scss';

/*
 *   Subnav link
 */
function SubNavItem({ el, hasFocus }) {
  const ref = useRef(null);

  useEffect(() => {
    if (hasFocus) ref.current.focus();
  }, [hasFocus]);

  return cloneElement(el, { linkRef: ref });
}

export function MobileDropdown({ children, shouldRender }) {
  const transitions = useTransition(shouldRender, null, {
    config: { mass: 1, tension: 210 },
    from: { transform: 'translate3d(-100%,0,0)' },
    enter: { transform: 'translate3d(0%,0,0)' },
    leave: { transform: 'translate3d(-100%,0,0)' },
  });

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div
          tabIndex={-1}
          key={key}
          style={props}
          className={classnames(styles.mobile_dropdown)}
        >
          {children}
        </animated.div>
      )
  );
}

function Dropwdown({
  mainNavigation,
  featuredContent,
  dropdownItemsChunks,
  navigationFooterLink,
  windowSize,
  columns,
}) {
  const isProductsItem =
    mainNavigation?.label?.toLowerCase() === 'products overview';

  return (
    <>
      {((windowSize.width < 901 &&
        (mainNavigation.isReplacement || mainNavigation.description)) ||
        windowSize.width > 900) && (
        <div className={styles.navItemDescription}>
          <div className={styles.navItemDescriptionHeader}>
            {mainNavigation.icon && (
              <div className={styles.dropdownItemIcon}>
                <img src={mainNavigation.icon.file.url} />
              </div>
            )}
            {mainNavigation.to ? (
              <SmartLink
                to={mainNavigation.to}
                className={styles.dropdown__item}
              >
                {mainNavigation.label}
                <span className={styles.iconCaretRight}>
                  <RightCaretIcon />
                </span>
              </SmartLink>
            ) : (
              <h3>{mainNavigation.label}</h3>
            )}

            {mainNavigation.description && <p>{mainNavigation.description}</p>}
          </div>
          {featuredContent && windowSize.width > 900 && (
            <div className={styles.navItemDescriptionFooter}>
              {featuredContent.icon && (
                <div className={styles.featuredContentIcon}>
                  <img src={featuredContent.icon.file.url} />
                </div>
              )}
              <SmartLink
                to={featuredContent.url}
                className={styles.dropdown__item}
              >
                {featuredContent.title}
                <span className={styles.iconCaretRight}>
                  <RightCaretIcon />
                </span>
              </SmartLink>
            </div>
          )}
        </div>
      )}
      <div
        className={classnames(styles.childrenWrapper, {
          [styles.noDescription]:
            windowSize.width < 901 &&
            !mainNavigation.isReplacement &&
            !mainNavigation.description,
        })}
      >
        <div className={styles.childrenContainer}>
          {dropdownItemsChunks.map((items, index) => (
            <div
              key={`chunk-${index}`}
              className={classnames({
                [styles.itemContainer]: !isProductsItem,
                [styles.itemContainerFlex]: isProductsItem,
              })}
              style={
                !isProductsItem
                  ? {
                      gridTemplateRows: `repeat(${Math.min(columns, 6)}, 1fr)`,
                    }
                  : {}
              }
            >
              {items}
            </div>
          ))}

          {featuredContent && windowSize.width < 901 && (
            <div className={styles.navItemDescriptionFooter}>
              {featuredContent.icon && (
                <div className={styles.featuredContentIcon}>
                  <img src={featuredContent.icon.file.url} />
                </div>
              )}
              <SmartLink
                to={featuredContent.url}
                className={styles.dropdown__item}
              >
                {featuredContent.title}
                <span className={styles.iconCaretRight}>
                  <RightCaretIcon />
                </span>
              </SmartLink>
            </div>
          )}
        </div>
        {navigationFooterLink && (
          <div className={styles.navigationFooterLink}>
            <SmartLink
              to={navigationFooterLink.url}
              className={styles.dropdown__item}
            >
              {navigationFooterLink.title}
              <span className={styles.iconCaretRight}>
                <RightCaretIcon />
              </span>
            </SmartLink>
          </div>
        )}
      </div>
    </>
  );
}

/*
 *   Nav Item
 */
export function NavItem({
  to,
  id: key,
  label,
  icon,
  description,
  accordion,
  children,
  forceClose,
  onClick,
  isSelected,
  onSelectedNavItem,
  featuredContent,
  mainNavigationReplacement,
  navigationFooterLink,
  showAnnouncement,
  sublinks,
  columns,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const caretRef = useRef(null);
  const isOpenRef = useRef(false);
  const currentFocusRef = useRef(-1);
  const [currentFocus, setCurrentFocus] = useState(-1);
  const windowSize = useWindowSize(100);

  useEffect(() => {
    if (!isSelected) {
      setIsOpen(false);
    }
  }, [isSelected]);

  function open(evt) {
    if (evt) evt.preventDefault();
    setIsOpen(true);
    currentFocusRef.current++;
    setCurrentFocus(currentFocusRef.current);
    Mousetrap.bind(['tab', 'down', 'enter'], handleDownArrow);
    Mousetrap.bind(['shift-tab', 'up'], handleUpArrow);
    Mousetrap.bind(['esc'], handleUpArrow);
  }

  function close() {
    currentFocusRef.current = -1;
    setCurrentFocus(currentFocusRef.current);
    setIsOpen(false);
    Mousetrap.unbind(['tab', 'shift+tab', 'up', 'down', 'esc']);
  }

  function toggle() {
    onSelectedNavItem(key);
    if (accordion) {
      setIsOpen(!isOpen);
    } else {
      if (!isOpenRef.current) {
        open();
      } else {
        close();
      }
    }
  }

  function handleDownArrow(e) {
    e.preventDefault();

    if (!isOpenRef.current) {
      open();
    } else if (currentFocusRef.current + 1 < children.length) {
      currentFocusRef.current++;
      setCurrentFocus(currentFocusRef.current);
    } else {
      close();
    }
  }

  function handleUpArrow(e) {
    e.preventDefault();

    if (currentFocusRef.current < 1) {
      close();
    } else {
      currentFocusRef.current--;
      setCurrentFocus(currentFocusRef.current);
      //sublinkRefs[--currentFocusRef.current].current.focus();
    }
  }

  function handleCaretFocus() {
    Mousetrap.bind(['enter', 'space'], open);
    Mousetrap.bind(['enter', 'down'], handleDownArrow);
  }

  function handleCaretBlur() {
    Mousetrap.unbind(['enter', 'space']);

    if (!isOpenRef.current) Mousetrap.unbind(['enter', 'tab', 'down']);
  }

  useEffect(() => {
    isOpenRef.current = isOpen;
  }, [isOpen]);

  useEffect(() => {
    return () => {
      Mousetrap.unbind(['tab', 'shift+tab', 'up', 'enter', 'space', 'esc']);
    };
  }, []);

  useEffect(close, [forceClose]);

  useEffect(() => {
    const locationMenuLink = Array.from(
      document.querySelectorAll('main header nav > div')
    ).find((div) => div.textContent.startsWith('Choose'));

    if (locationMenuLink) {
      locationMenuLink.classList.add('location');

      locationMenuLink.addEventListener('click', (event) => {
        const text = getShortStateName(event.target.textContent);
        localStorage.setItem('state', text);
        locationMenuLink.querySelector('a').textContent = text;
      });

      const state = localStorage.getItem('state');

      if (state && state !== 'undefined') {
        locationMenuLink.querySelector('a').textContent = state;
      } else {
        locationMenuLink.querySelector('a').textContent = 'Choose your state';
      }
    }
  });

  function getShortStateName(state) {
    return {
      Alabama: 'AL',
      Alaska: 'AK',
      Arizona: 'AZ',
      Arkansas: 'AR',
      California: 'CA',
      Colorado: 'CO',
      Connecticut: 'CT',
      Delaware: 'DE',
      Florida: 'FL',
      Georgia: 'GA',
      Hawaii: 'HI',
      Idaho: 'ID',
      Illinois: 'IL',
      Indiana: 'IN',
      Iowa: 'IA',
      Kansas: 'KS',
      Kentucky: 'KY',
      Louisiana: 'LA',
      Maine: 'ME',
      Maryland: 'MD',
      Massachusetts: 'MA',
      Michigan: 'MI',
      Minnesota: 'MN',
      Missouri: 'MO',
      Montana: 'MT',
      Nebraska: 'NE',
      Nevada: 'NV',
      'New Hampshire': 'NH',
      'New Jersey': 'NJ',
      'New Mexico': 'NM',
      'New York': 'NY',
      'North Carolina': 'NC',
      'North Dakota': 'ND',
      Ohio: 'OH',
      Oklahoma: 'OK',
      Oregon: 'OR',
      Pennsylvania: 'PA',
      'Rhode Island': 'RI',
      'South Carolina': 'SC',
      'South Dakota': 'SD',
      Tennessee: 'TN',
      Texas: 'TX',
      Utah: 'UT',
      Vermont: 'VT',
      Virginia: 'VA',
      Washington: 'WA',
      'West Virginia': 'WV',
      Wisconsin: 'WI',
      Wyoming: 'WY',
    }[state];
  }

  if ((children && children.length) || (sublinks && sublinks.length)) {
    let dropdownItems = [];
    let dropdownItemsChunks = [];
    const hasCategory = some(sublinks, 'category');

    if (sublinks && hasCategory) {
      const subLinksGroupedBy = groupBy(sublinks, 'category');
      const categories = Object.keys(subLinksGroupedBy);

      dropdownItems = categories
        .map((category) => {
          return subLinksGroupedBy[category].map((sublink, i) => (
            <div key={`sublink-${category || ''}-${i}`}>
              {i === 0 && category && category !== 'null' && (
                <h3 className={styles.categoryTitle}>{category}</h3>
              )}
              <SubNavItem
                key={i}
                hasFocus={currentFocus === i}
                el={
                  <NavDropdownItem
                    key={sublink.id}
                    to={sublink.url}
                    category={sublink.category}
                    onClick={onClick}
                    description={sublink.description}
                    icon={sublink.icon}
                  >
                    {sublink.title}
                  </NavDropdownItem>
                }
              />
            </div>
          ));
        })
        .flat();
    } else {
      dropdownItems = children.map((child, i) => (
        <SubNavItem key={i} hasFocus={currentFocus === i} el={child} />
      ));
    }
    dropdownItemsChunks = chunk(dropdownItems, columns);

    const mainNavigation = mainNavigationReplacement
      ? {
          icon: mainNavigationReplacement.icon,
          label: mainNavigationReplacement.title,
          description: mainNavigationReplacement.description,
          to: mainNavigationReplacement.url,
          isReplacement: !!mainNavigationReplacement,
        }
      : { icon, label, description, to };

    return (
      <>
        <div
          className={classnames(styles.menu__parent, {
            [styles.is_open]: isOpen,
            [styles.accordion]: accordion,
          })}
        >
          <div className={styles.menu__item}>
            <button
              ref={caretRef}
              className={styles.menu__caret}
              onFocus={handleCaretFocus}
              onBlur={handleCaretBlur}
              aria-label={`${label} dropdown`}
              onClick={toggle}
              aria-expanded={isOpen}
            >
              {accordion || !to ? (
                <div className={styles.menu__link}>
                  {label}
                  {windowSize.width < 901 && (
                    <span className={styles.iconCaretRight}>
                      <RightCaretIcon />
                    </span>
                  )}
                </div>
              ) : (
                <SmartLink to={to} className={styles.menu__link}>
                  {label}
                  {windowSize.width < 901 && (
                    <span className={styles.iconCaretRight}>
                      <RightCaretIcon />
                    </span>
                  )}
                </SmartLink>
              )}
              {windowSize.width > 900 && <CaretIcon title="Open dropdown" />}
            </button>

            {windowSize.width > 900 && (
              <div
                className={classnames(styles.dropdown, {
                  [styles.bannerVisible]: showAnnouncement,
                })}
              >
                <Dropwdown
                  mainNavigation={mainNavigation}
                  featuredContent={featuredContent}
                  dropdownItemsChunks={dropdownItemsChunks}
                  navigationFooterLink={navigationFooterLink}
                  windowSize={windowSize}
                  columns={columns}
                />
              </div>
            )}
          </div>
        </div>
        <MobileDropdown shouldRender={windowSize.width < 901 && isOpen}>
          <div className={styles.mobileContainer}>
            <button
              className={classnames(styles.mobileDropdownClose, {
                [styles.closeNoDescription]:
                  !mainNavigation.description && !mainNavigation.isReplacement,
              })}
              onClick={toggle}
            >
              <span className={styles.iconCaretLeft}>
                <RightCaretIcon />
              </span>
              {label}
            </button>
            <Dropwdown
              mainNavigation={mainNavigation}
              featuredContent={featuredContent}
              dropdownItemsChunks={dropdownItemsChunks}
              navigationFooterLink={navigationFooterLink}
              windowSize={windowSize}
            />
          </div>
        </MobileDropdown>
      </>
    );
  } else if (label) {
    return (
      <SmartLink
        onClick={onClick}
        to={to}
        className={classnames(styles.menu__link, {
          [styles.accordion]: accordion,
        })}
      >
        {label}
      </SmartLink>
    );
  } else {
    return (
      <div className={classnames(styles.custom_menu_link)}>{children}</div>
    );
  }
}

/*
 *   Dropdown item
 */
export function NavDropdownItem({
  to,
  children,
  linkRef,
  onClick,
  description,
  icon,
}) {
  return (
    <SmartLink
      ref={linkRef}
      to={to}
      onClick={onClick}
      className={styles.dropdownLink}
    >
      <div className={styles.item}>
        {icon && (
          <div className={styles.dropdownItemIcon}>
            <img src={icon.file.url} />
          </div>
        )}
        <div
          className={classnames(styles.dropdownItemInformation, {
            [styles.noDescription]: !description,
          })}
        >
          <div className={styles.dropdown__item}>{children}</div>
          {description && <p>{description}</p>}
        </div>
      </div>
    </SmartLink>
  );
}
