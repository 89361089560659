import React, { useMemo } from 'react';
import classnames from 'classnames';
import Img from 'gatsby-image/withIEPolyfill';
import { useInView } from 'react-intersection-observer';
import SmartLink from './smartLink';
import RichText from './richText';
import styles from './pressHighlight.module.scss';
import { FluidObject } from 'gatsby-image';

interface Article {
  id: string;
  title: string;
  publication: string;
  author: string;
  date: string;
  articleUrl: string;
  excerpt: {
    json: any;
  };
  image: {
    fluid: FluidObject;
  };
}

interface PressHighlightProps {
  title: string;
  buttonText: string;
  buttonUrl: string;
  backgroundColor: string;
  sectionId: string;
  contentBlocks: Article[];
  isFirstElement: boolean;
}

interface ArticleCardProps {
  title: string;
  image: {
    fluid: FluidObject;
  };
  publication: string;
  author: string;
  excerpt: {
    json: any;
  };
  date: string;
  url: string;
}

export default function PressHighlight({
  title,
  buttonText,
  buttonUrl,
  backgroundColor,
  sectionId,
  contentBlocks,
  isFirstElement,
}: PressHighlightProps) {
  const [containerRef, inView] = useInView({ triggerOnce: true });
  const TitleElementTag = isFirstElement ? 'h1' : 'h2';
  const ArticleTitleElementTag = isFirstElement ? 'h2' : 'h3';

  function ArticleCard({
    title,
    image,
    publication,
    author,
    excerpt,
    date,
    url,
  }: ArticleCardProps) {
    const formatDate = (date: string) =>
      new Date(date).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });

    return (
      <article id={sectionId} className={styles.article}>
        {image && <Img className={styles.article__image} fluid={image.fluid} />}
        <div className={styles.article__text}>
          <div className={styles.article__publication}>{publication}</div>
          <div className={styles.article__author}>{author}</div>
          <ArticleTitleElementTag className={styles.article__title}>
            {title}
          </ArticleTitleElementTag>
          {excerpt && (
            <RichText
              className={styles.article__excerpt}
              content={excerpt.json}
            />
          )}
          <footer className={styles.article__footer}>
            <SmartLink to={url} className={styles.article__link}>
              See more
            </SmartLink>
            {date && (
              <div className={styles.article__date}>{formatDate(date)}</div>
            )}
          </footer>
        </div>
      </article>
    );
  }

  const articleEls = useMemo(
    () =>
      contentBlocks?.map((a) => (
        <ArticleCard
          key={a.id}
          title={a.title}
          image={a.image}
          publication={a.publication}
          author={a.author}
          excerpt={a.excerpt}
          date={a.date}
          url={a.articleUrl}
        />
      )) || null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentBlocks]
  );

  /*
   *   Return
   */

  return (
    <section
      ref={containerRef}
      className={classnames(styles.container, styles[backgroundColor], {
        [styles.revealed]: inView,
      })}
    >
      <div className={styles.inner}>
        <TitleElementTag className={styles.title}>{title}</TitleElementTag>
        <div className={styles.articles}>{articleEls}</div>
        {buttonText && (
          <SmartLink className={styles.button} to={buttonUrl}>
            {buttonText}
          </SmartLink>
        )}
      </div>
    </section>
  );
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
