import React, { useState } from 'react';
import classnames from 'classnames';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import RichText from 'components/richText';
import TextWithBr from 'components/textWithBr';
import SmartLink from 'components/smartLink';
import styles from 'components/complexText.module.scss';

export default function ComplexText({
  title,
  label,
  sectionId,
  tab1Title,
  tab1Text1,
  tab1Text2,
  tab2Title,
  tab2Text1,
  tab2Text2,
  tab3Title,
  tab3Text1,
  tab3Text2,
  backgroundColor,
  buttonText,
  buttonUrl,
  buttonTarget,
}) {
  const [containerRef, inView] = useInView({ triggerOnce: true });
  const [currentTab, setCurrentTab] = useState('tab1');

  const tabsData = {
    tab1: {
      key: 'tab1',
      title: tab1Title,
      text1: tab1Text1,
      text2: tab1Text2,
    },
    tab2: {
      key: 'tab2',
      title: tab2Title,
      text1: tab2Text1,
      text2: tab2Text2,
    },
    tab3: {
      key: 'tab3',
      title: tab3Title,
      text1: tab3Text1,
      text2: tab3Text2,
    },
  };

  const tabsWithContent = Object.keys(tabsData).filter(
    (tabKey) => tabsData[tabKey].text1 || tabsData[tabKey].text2
  );

  const tabEls = tabsWithContent.map((tabKey) => {
    const tab = tabsData[tabKey];
    return (
      <button
        key={tab.key}
        className={classnames(styles.tab, {
          [styles.current]: tab.key === currentTab,
        })}
        onClick={() => setCurrentTab(tab.key)}
      >
        <span>{tab.title}</span>
      </button>
    );
  });

  /*
   *   Return
   */
  // ...

  /*
   *   Return
   */
  return (
    <section
      id={sectionId}
      ref={containerRef}
      className={classnames(styles.container, styles[backgroundColor], {
        [styles.revealed]: inView,
      })}
    >
      <div className={styles.inner}>
        {label && <div className={styles.label}>{label}</div>}
        {title && (
          <TextWithBr className={styles.title} content={title} tag="h1" />
        )}

        <div className={styles.content}>
          <div className={styles.left}>
            <div className={styles.tabs}>{tabEls}</div>
            {tabsData[currentTab].text1 && (
              <RichText
                className={classnames(
                  styles.left__text,
                  styles['complex-text-link']
                )}
                content={tabsData[currentTab].text1.json}
              />
            )}
          </div>
          {tabsData[currentTab].text2 && (
            <RichText
              className={styles.right}
              content={tabsData[currentTab].text2.json}
            />
          )}
          {currentTab === 'tab3' && tabsData.tab3.text1 && (
            <RichText
              className={styles.right}
              content={tabsData.tab3.text1.json}
            />
          )}
          {currentTab === 'tab3' && tabsData.tab3.text2 && (
            <RichText
              className={classnames(styles.right, styles.complex_text__link)}
              content={tabsData.tab3.text2.json}
            />
          )}
        </div>

        {buttonText && (
          <footer className={styles.footer}>
            <SmartLink
              className={styles.button}
              to={buttonUrl}
              target={buttonTarget === 'New Tab' ? '_blank' : null}
            >
              {buttonText}
            </SmartLink>
          </footer>
        )}
      </div>
    </section>
  );
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const complexTextFragment = graphql`
  fragment ComplexTextFragment on Node {
    ... on ContentfulComplexText {
      title
      sectionId
      label
      tab1Title
      tab1Text1 {
        json
      }
      tab1Text2 {
        json
      }
      tab2Title
      tab2Text1 {
        json
      }
      tab2Text2 {
        json
      }
      tab3Title
      tab3Text1 {
        json
      }
      tab3Text2 {
        json
      }
      buttonText
      buttonUrl
      buttonTarget
      backgroundColor
    }
  }
`;
