import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';
import csv from 'csvtojson';
import slugify from '../helpers/slugify';
import removeBr from '../helpers/removeBr';
import RichText from '../components/richText';
import TextWithBr from './textWithBr';
import FeatureTable, { FeatureTableProps } from '../components/featureTable';
import { Choosy, OptionGroup, Option } from '../components/choosy';
import SmartLink from '../components/smartLink';
import styles from './featureTableGroup.module.scss';

interface LocationProps {
  hash?: string;
  host: string;
  hostname: string;
  href: string;
  key: string;
  origin: string;
  pathname: string;
  port: string;
  protocol: string;
  search?: string;
  state?: any;
}

interface FeatureTableGroupProps {
  title: string;
  contentBlocks: FeatureTableProps[];
  location?: LocationProps;
}

export default function FeatureTableGroup({
  title,
  contentBlocks: featureTables,
  location,
}: FeatureTableGroupProps) {
  const [containerRef, inView] = useInView({ triggerOnce: true });
  const [rowData, setRowData] = useState({});
  const [currentTableId, setCurrentTableId] = useState(
    (featureTables?.length && featureTables[0]?.id) || null
  );
  const currentTableData = {
    ...featureTables.filter(
      (featureTable) => featureTable.id === currentTableId
    )[0],
    rows: rowData[currentTableId]
      ? rowData[currentTableId].filter((row) => row['Row Group'])
      : null,
  };

  /*
   *   hash-based tab nav
   */
  useEffect(() => {
    if (location.hash) {
      const table = featureTables.filter(
        (featureTable) =>
          slugify(featureTable.title).toLowerCase() === slugify(location.hash)
      )[0];
      if (table && table.id !== currentTableId) setCurrentTableId(table.id);
    }
  }, [currentTableId, featureTables, location.hash]);

  /*
   *   Fetch and process CSV data
   */
  useEffect(() => {
    Promise.all(
      featureTables.map(({ id, spreadsheet }) =>
        getCSVData(spreadsheet?.file?.url, id)
      )
    ).then((data) => {
      setRowData(
        // @ts-ignore
        data?.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.data }), {})
      );
    });
  }, [featureTables]);

  /*
   *   Tabs
   */
  const tableCategories = featureTables.reduce(
    (acc, curr) => acc.add(curr.category),
    new Set()
  );

  const tabGroupEls = Array.from(tableCategories)?.map(
    (tableCategory: string) => {
      const tabEls = featureTables
        .filter((featureTable) => featureTable.category === tableCategory)
        .map((featureTable) => (
          <TextWithBr
            tag="a"
            content={featureTable.title}
            key={featureTable.id}
            // @ts-ignore
            href={`#${slugify(featureTable.title).toLowerCase()}`}
            onClick={() => setCurrentTableId(featureTable.id)}
            className={classnames(styles.tab, {
              [styles.current]: currentTableId === featureTable.id,
            })}
          />
        ));

      return (
        <div key={tableCategory} className={styles.tab_group}>
          <h4 className={styles.tab_group__title}>{tableCategory}</h4>
          <div className={styles.tab_group__tabs}>{tabEls}</div>
        </div>
      );
    }
  );

  const choosyTabGroupEls = Array.from(tableCategories).map(
    (tableCategory: string) => {
      const tabEls = featureTables
        .filter((featureTable) => featureTable.category === tableCategory)
        .map((featureTable) => (
          <Option
            key={featureTable.id}
            onClick={() => setCurrentTableId(featureTable.id)}
            className={classnames(styles.choosy_option, {
              [styles.current]: currentTableId === featureTable.id,
            })}
          >
            {removeBr(featureTable.title)}
          </Option>
        ));

      return (
        <OptionGroup key={tableCategory} title={tableCategory}>
          {tabEls}
        </OptionGroup>
      );
    }
  );

  const currentTabTitle = featureTables.filter(
    (featureTable) => featureTable.id === currentTableId
  )[0].title;

  /*
   *   Tables
   */
  const tableEls = featureTables.map((featureTable) => {
    const data = {
      ...featureTable,
      rows: rowData[featureTable.id]
        ? rowData[featureTable.id].filter((row) => row['Row Group'])
        : null,
    };

    return featureTable.id === currentTableId ? (
      <FeatureTable key={featureTable.id} data={data} />
    ) : null;
  });

  /*
   *   Return
   */
  return (
    <section
      className={classnames(styles.container, {
        [styles.revealed]: inView,
      })}
    >
      <header className={styles.header} ref={containerRef}>
        <h1 className={styles.title}>{title}</h1>
        {tabGroupEls.length > 1 && (
          <div className={styles.tabs}>{tabGroupEls}</div>
        )}
        {tabGroupEls.length > 1 && (
          <div className={styles.choosy_container}>
            <div className={styles.choosy_label}>Browse Products</div>
            <Choosy className={styles.choosy} label={currentTabTitle}>
              {choosyTabGroupEls}
            </Choosy>
          </div>
        )}
        {currentTableData.description && (
          <RichText
            className={styles.description}
            content={currentTableData.description.json}
          />
        )}

        <div className={styles.legend}>
          <div className={styles.legend__item}>
            <h4 className={styles.legend__title}>
              {currentTableData.column1Title}
            </h4>
            {currentTableData.column1LinkUrl && (
              <SmartLink
                className={styles.legend__link}
                to={currentTableData.column1LinkUrl}
              >
                {currentTableData.column1LinkText}
              </SmartLink>
            )}
          </div>
          {currentTableData.column2Title && (
            <div className={styles.legend__item}>
              <h4 className={styles.legend__title}>
                {currentTableData.column2Title}
              </h4>
              {currentTableData.column2LinkUrl && (
                <SmartLink
                  className={styles.legend__link}
                  to={currentTableData.column2LinkUrl}
                >
                  {currentTableData.column2LinkText}
                </SmartLink>
              )}
            </div>
          )}
          {currentTableData.column3Title && (
            <div className={styles.legend__item}>
              <h4 className={styles.legend__title}>
                {currentTableData.column3Title}
              </h4>
              {currentTableData.column3LinkUrl && (
                <SmartLink
                  className={styles.legend__link}
                  to={currentTableData.column3LinkUrl}
                >
                  {currentTableData.column3LinkText}
                </SmartLink>
              )}
            </div>
          )}
          {currentTableData.column4Title && (
            <div className={styles.legend__item}>
              <h4 className={styles.legend__title}>
                {currentTableData.column4Title}
              </h4>
              {currentTableData.column4LinkUrl && (
                <SmartLink
                  className={styles.legend__link}
                  to={currentTableData.column4LinkUrl}
                >
                  {currentTableData.column4LinkText}
                </SmartLink>
              )}
            </div>
          )}
        </div>
      </header>
      {tableEls}
    </section>
  );
}

/*
 *   Fetch and process .csv files
 */
function getCSVData(url, id) {
  return fetch(url)
    .then((data) => {
      const text = data.text();
      return text;
    })
    .then((text) => {
      return csv()
        .fromString(text)
        .then((jsonObj) => ({ id, data: jsonObj }));
    })
    .catch((err) => console.log(err));
}
