import React, { useState } from 'react';
import classNames from 'classnames';
import RichText from './richText';
import SmartLink from './smartLink';
import Plus from '../images/plus.svg';
import Minus from '../images/minus.svg';
import styles from './dropdownCards.module.scss';

const DropdownCard = ({ title, description, buttonText, buttonUrl }) => {
  const [open, setOpen] = useState(false);

  const handleOnClick = () => setOpen((prevState) => !prevState);

  return (
    <div className={styles.collapsibleCard}>
      <div className={styles.header}>
        <h3>{title}</h3>
        <div className={styles.buttonContainer}>
          <button onClick={() => handleOnClick()}>
            {open ? (
              <img src={Minus} alt="minus-icon" />
            ) : (
              <img src={Plus} alt="plus-icon" />
            )}
          </button>
        </div>
      </div>
      <div
        className={classNames(styles.content, {
          [styles.open]: open,
          [styles.hidden]: !open,
        })}
      >
        <div className={styles.contentContainer}>
          {description && (
            <RichText
              className="template"
              content={description?.json || description}
            />
          )}
          {buttonUrl && (
            <SmartLink
              to={buttonUrl}
              aria-label={buttonText}
              className={styles.link}
            >
              {buttonText}
            </SmartLink>
          )}
        </div>
      </div>
    </div>
  );
};

const DropdownCards = ({ title, description, contentBlocks }) => {
  return (
    <section className={styles.container}>
      <div className={styles.inner}>
        {title && <h2>{title}</h2>}
        {description && <RichText content={description?.json || description} />}
        {contentBlocks?.map(({ title, description, buttonText, buttonUrl }) => (
          <DropdownCard
            title={title}
            description={description}
            buttonText={buttonText}
            buttonUrl={buttonUrl}
          />
        ))}
      </div>
    </section>
  );
};

export default DropdownCards;
