import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import forceLayout from 'helpers/forceLayout';
import removeSmartQuotes from 'helpers/removeSmartQuotes';
import { useInView } from 'react-intersection-observer';
import useVidyard from 'hooks/useVidyard';
import Media from 'components/media';
import SmartLink from 'components/smartLink';
import RichText from 'components/richText';
import TextWithBr from 'components/textWithBr';
import { ReactComponent as LeftCaretIcon } from 'images/icon-caret-left.svg';
import { ReactComponent as RightCaretIcon } from 'images/icon-caret-right.svg';
import styles from './heroMixedSlideshow.module.scss';

export default function HeroMixedSlideshow({
  sectionId,
  slides,
  isFirstElement,
}) {
  const [containerRef, inView] = useInView({ triggerOnce: true });
  const innerRef = useRef(null);
  const slideRef = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [slideIsVisible, setSlideIsVisible] = useState(false);
  const currentSlide = slides && slides[currentSlideIndex];
  const rafRef = useRef(null);

  /*
   *   Go to slide
   */
  function goTo(index) {
    setSlideIsVisible(false);
    setCurrentSlideIndex(index);
  }

  /*
   *   Advance
   */
  function advance() {
    const nextIndex =
      currentSlideIndex < slides.length - 1 ? currentSlideIndex + 1 : 0;

    setSlideIsVisible(false);
    setCurrentSlideIndex(nextIndex);
  }

  /*
   *   Retreat
   */
  function retreat() {
    const prevIndex =
      currentSlideIndex <= 0 ? slides.length - 1 : currentSlideIndex - 1;

    setSlideIsVisible(false);
    setCurrentSlideIndex(prevIndex);
  }

  /*
   *   Pagination els
   */
  const paginationEls = slides
    ? slides.map((s, i) => (
        <button
          aria-label={`Go to slide ${i}`}
          key={s.id}
          className={classnames(styles.pagination_button, {
            [styles.current]: i === currentSlideIndex,
          })}
          onClick={() => goTo(i)}
        >
          Go to slide {i}
        </button>
      ))
    : null;

  /*
   *   Use Effect
   */
  useEffect(() => {
    if (!slideIsVisible) {
      forceLayout();
      rafRef.current = requestAnimationFrame(() => setSlideIsVisible(true));
    }

    return () => cancelAnimationFrame(rafRef.current);
  }, [slideIsVisible]);

  const MediaEl = () => {
    useVidyard(currentSlide.vidyardEmbed);

    return currentSlide?.vidyardEmbed ? (
      <div
        className={styles.slide__graphic}
        dangerouslySetInnerHTML={{
          __html: removeSmartQuotes(currentSlide.vidyardEmbed.vidyardEmbed),
        }}
      />
    ) : (
      <Media
        className={styles.slide__graphic}
        fileUrl={currentSlide?.graphic?.file.url}
        localFile={currentSlide?.graphic?.fluid}
        imageFormat="fluid"
        type={currentSlide?.graphic?.file.contentType}
        poster={currentSlide?.videoPoster}
        muted={false}
        autoplay={false}
        loop={false}
      />
    );
  };

  /*
   *   Return
   */
  return (
    <section
      id={sectionId}
      ref={containerRef}
      className={classnames(
        styles.container,
        currentSlide && styles[currentSlide.backgroundColor],
        {
          [styles.loaded]: inView,
          [styles.graphic_right]:
            currentSlide &&
            currentSlide.alignment &&
            currentSlide.alignment !== 'Graphic Left',
        }
      )}
    >
      <div className={styles.inner} ref={innerRef}>
        {currentSlide?.label && (
          <div className={styles.label_container}>
            {currentSlide.labelIcon && (
              <img
                src={currentSlide.labelIcon.file.url}
                alt={currentSlide.label || `slide-icon-${currentSlideIndex}`}
                loading="lazy"
              />
            )}
            <TextWithBr
              className={styles.label}
              content={currentSlide.label}
              tag="h3"
            />
          </div>
        )}
        <div className={styles.controls}>
          {slides && slides.length > 1 && (
            <button
              className={styles.control}
              onClick={retreat}
              aria-label="Previous slide"
            >
              <LeftCaretIcon />
            </button>
          )}
          <button
            className={styles.control}
            onClick={advance}
            aria-label="Next slide"
          >
            <RightCaretIcon />
          </button>
        </div>
        <div
          ref={slideRef}
          className={classnames({
            [styles.visible]: slideIsVisible,
            [styles.box_shadow]: currentSlide?.boxShadow,
            [styles.box_shadow]: currentSlide?.boxShadow,
          })}
        >
          <div className={styles.slide__content}>
            {currentSlide?.alignment === 'Graphic Left' &&
              (currentSlide?.graphic || currentSlide?.vidyardEmbed) && (
                <div className={styles.slide__graphic_container}>
                  <MediaEl />
                </div>
              )}
            <div
              className={classnames(styles.slide__text, {
                [styles.slide__text_label]: currentSlide?.label,
              })}
            >
              {currentSlide?.title && (
                <TextWithBr
                  className={styles.slide__title}
                  content={currentSlide.title}
                  tag={isFirstElement ? 'h1' : 'h2'}
                />
              )}

              {currentSlide?.intro && (
                <RichText
                  className={styles.slide__intro}
                  content={currentSlide.intro.json}
                />
              )}

              <div
                className={classnames({
                  [styles.slide_buttons_wrapper]:
                    currentSlide?.buttonText || currentSlide?.secondButtonText,
                })}
              >
                {currentSlide?.buttonText && (
                  <SmartLink
                    to={currentSlide.buttonUrl}
                    className={styles.slide__button}
                  >
                    {currentSlide.buttonText}
                  </SmartLink>
                )}
                {/* Second Button */}
                {currentSlide?.secondButtonText && (
                  <SmartLink
                    to={currentSlide.secondButtonUrl}
                    className={styles.slide__button}
                  >
                    {currentSlide.secondButtonText}
                  </SmartLink>
                )}
              </div>
            </div>
            {currentSlide?.alignment !== 'Graphic Left' &&
              (currentSlide?.graphic || currentSlide?.vidyardEmbed) && (
                <div className={styles.slide__graphic_container}>
                  <MediaEl />
                </div>
              )}
          </div>
          {currentSlide?.backgroundImage && (
            <div
              className={styles.slide__background}
              style={{
                backgroundImage: `url(${currentSlide.backgroundImage.file.url})`,
              }}
            />
          )}
        </div>
      </div>
      <div className={styles.pagination}>{paginationEls}</div>
    </section>
  );
}
