import React, { useState } from 'react';
import classnames from 'classnames';
import RichText from 'components/richText';
import styles from './stateStandards.module.scss';

function Standard({ title, leftColumn, rightColumn, sectionId }) {
  return (
    <article className={styles.standard} id={sectionId}>
      <header className={styles.standard__header}>
        <h1 className={styles.standard__title}>{title}</h1>
      </header>
      <div className={styles.standard__columns}>
        {leftColumn && <RichText className={styles.standard__column} content={leftColumn.json} />}
        {rightColumn && <RichText className={styles.standard__column} content={rightColumn.json} />}
      </div>
    </article>
  );
}

export default function StateStandards({ title, description, contentBlocks }) {
  const [filter, setFilter] = useState(null);
  const standards = contentBlocks
    .filter(cb => {
      const regex = new RegExp(`([${filter}])`, 'g');
      if (
        !filter ||
        cb.title
          .toLowerCase()
          .charAt(0)
          .match(regex)
      )
        return true;
      return false;
    })
    .map(cb => <Standard key={cb.id} {...cb} />);

  return (
    <section styles={styles.container}>
      <header className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
        <RichText className={styles.description} content={description.json} />
      </header>
      <div className={styles.standards}>
        <div className={styles.standards__inner}>
          <div className={styles.standards__filter}>
            <button
              className={classnames(styles.standards__filter_button, { [styles.current]: !filter })}
              onClick={() => setFilter(null)}
            >
              All States
            </button>
            <button
              className={classnames(styles.standards__filter_button, { [styles.current]: filter === 'a-g' })}
              onClick={() => setFilter('a-g')}
            >
              A - G
            </button>
            <button
              className={classnames(styles.standards__filter_button, { [styles.current]: filter === 'h-p' })}
              onClick={() => setFilter('h-p')}
            >
              H - P
            </button>
            <button
              className={classnames(styles.standards__filter_button, { [styles.current]: filter === 'q-v' })}
              onClick={() => setFilter('q-v')}
            >
              Q - V
            </button>
            <button
              className={classnames(styles.standards__filter_button, { [styles.current]: filter === 'w-z' })}
              onClick={() => setFilter('w-z')}
            >
              W - Z
            </button>
          </div>
          {standards}
        </div>
      </div>
    </section>
  );
}
