import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';
import Img from 'gatsby-image/withIEPolyfill';
import Zoetrope from 'components/zoetrope';
import styles from './media.module.scss';

/*
 *   Display a muted auto-play video or image
 */
export default function Media({
  type,
  fileUrl,
  localFile,
  imageFormat,
  alt,
  className,
  poster,
  muted = true,
  autoplay = true,
  loop = true,
}) {
  const [vidRef, inView] = useInView({ triggerOnce: true });
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    requestAnimationFrame(() => setIsLoaded(true));
  }, []);

  // Video
  if (type.includes('video')) {
    const elClasses = classnames(styles.video, className, {
      [styles.loaded]: isLoaded,
    });

    return (
      <>
        <div ref={vidRef}></div>
        <Zoetrope
          className={elClasses}
          src={inView ? fileUrl : null}
          type={type}
          poster={poster ? poster.file.url : null}
          autoPlay={autoplay}
          muted={muted}
          loop={loop}
          playsInline
        />
      </>
    );
  } else if (type === 'image/gif' && localFile) {
    const elClasses = classnames(styles.image, className, {
      [styles.loaded]: isLoaded,
    });
    return (
      <img className={elClasses} alt={alt} src={localFile.src} loading="lazy" />
    );
  } else if (type.includes('image') && localFile) {
    const elClasses = classnames(styles.image, className, {
      [styles.loaded]: isLoaded,
    });

    const imgSrc =
      imageFormat === 'fluid' ? { fluid: localFile } : { fixed: localFile };

    return <Img className={elClasses} alt={alt} {...imgSrc} />;
  } else if (fileUrl) {
    const elClasses = classnames(styles.image, className, {
      [styles.loaded]: isLoaded,
    });
    return <img src={fileUrl} className={elClasses} alt={alt} loading="lazy" />;
  } else {
    return null;
  }
}
