import { useState, useEffect } from 'react';

const isClient = typeof window === 'object';

export default function useVidyard(vidyardEmbed) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let scriptElement = null;

    const loadScript = () => {
      scriptElement = document.createElement('script');
      scriptElement.src = `https://play.vidyard.com/embed/v4.js?forceReload=${Date.now()}`;
      scriptElement.async = true;
      document.head.appendChild(scriptElement);
      setIsLoaded(true);
    };

    if (!isLoaded && isClient && vidyardEmbed) {
      loadScript();
    }

    return () => {
      if (isClient && scriptElement && scriptElement.parentNode) {
        scriptElement.parentNode.removeChild(scriptElement);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
