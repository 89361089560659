import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';
import checkForMobile from 'is-mobile';
import colorTheme from 'helpers/colorTheme';
import TextWithBr from 'components/textWithBr';
import EmbeddedContent from 'components/embeddedContent';
import styles from './embeddedWidget.module.scss';

export default function EmbeddedWidget({
  title,
  intro,
  embedPrefix,
  embedCode,
  backgroundColor,
  colorTheme: colorWorld,
  constrainToColumn,
  sectionId,
  hideOnMobileDevices
}) {
  const isMobile = useMemo(() => checkForMobile(), []);
  const withText = title || intro

  return isMobile && hideOnMobileDevices ? null : (
    <section
      id={sectionId}
      style={colorTheme('background-color', backgroundColor, colorWorld === 'Dark' ? 'dark' : 'light')}
      className={classnames(styles.container, styles[backgroundColor], styles[colorWorld], { [styles.column]: constrainToColumn, [styles.with_text]: withText })}
    >
      <div className={styles.inner}>
        {withText && <div className={styles.text}>
        {title && <TextWithBr tag={'h1'} className={styles.title} content={title} />}
        {intro && <TextWithBr className={styles.intro} content={intro} />}
        </div>}
        <div className={styles.code}>
          {embedPrefix && <div className={styles.embed_prefix}>{embedPrefix}</div>}
          <EmbeddedContent content={embedCode.embedCode}/>
        </div>
      </div>
    </section>
  );
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const embeddedWidgetFragment = graphql`
  fragment EmbeddedWidgetFragment on Node {
    ... on ContentfulEmbeddedWidget {
      sectionId
      title
      intro
      embedPrefix
      embedCode {
        embedCode
      }
      backgroundColor
      colorTheme
      constrainToColumn
      hideOnMobileDevices
    }
  }
`;
