import React, { forwardRef, ForwardedRef } from 'react';
import sanitizeHtml from 'sanitize-html';

type HTMLTagNames =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'a'
  | 'span'
  | 'div';

interface TextWithBrProps {
  tag: HTMLTagNames;
  content: string;
  className: string;
}

const TextWithBr = forwardRef<HTMLElement, TextWithBrProps>((props, ref) => {
  const Tag = props.tag || 'div';

  return (
    <Tag
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(props.content, { allowedTags: ['br'] }),
      }}
      // @ts-ignore
      ref={ref as ForwardedRef<HTMLElement>}
      {...props}
    />
  );
});

export default TextWithBr;
