import React, { useEffect, useRef } from 'react';
import removeSmartQuotes from 'helpers/removeSmartQuotes';
const marketoForms = new Set();

function extractAndAppendScripts(container, content) {
  const tempContainerEl = document.createElement('div');
  tempContainerEl.innerHTML = content;
  const remoteScripts = [];
  const inlineScripts = [];
  let numLoaded = 0;

  function onScriptLoad() {
    numLoaded++;
    if (numLoaded >= remoteScripts.length - 1) {
      inlineScripts.forEach((scriptEl) => container.appendChild(scriptEl));
    }
  }

  tempContainerEl.querySelectorAll('script').forEach((script) => {
    const el = document.createElement('script');
    if (script.src) {
      el.addEventListener('load', onScriptLoad);
      el.src = script.src;
      remoteScripts.push(el);
    } else {
      if (!marketoForms.has(script.src)) {
        if (script.textContent.includes('MktoForms2.loadForm'))
          marketoForms.add(script.textContent);
        el.textContent = `try{${script.textContent}}catch(err){ console.log(err) }`;
        inlineScripts.push(el);
      }
    }
    script.remove();
  });

  container.innerHTML = tempContainerEl.innerHTML;
  remoteScripts.forEach((scriptEl) => container.appendChild(scriptEl));
}

export default function EmbeddedContent({ content }) {
  const containerElRef = useRef(null);

  useEffect(() => {
    extractAndAppendScripts(containerElRef.current, removeSmartQuotes(content));
  }, [content]);

  return <div ref={containerElRef}></div>;
}
