import { useState, useEffect } from 'react';

const useProductComparisonData = () => {
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    const data = [
      {
        id: 'lite',
        title: 'Newsela Lite',
        subtitle:
          'Get a taste of Newsela’s premium differentiated content, engaging activities, and real-time data each week.',
        buttonText: 'Sign up for free',
        buttonUrl: 'https://newsela.com/join/welcome/',
        openInNewTab: false,
        features: [
          '4 news articles at a time',
          'Automatic differentiation and embedded scaffolds',
          'Standards-aligned quizzes and writing prompts',
          'Flexible assignments for individuals or small groups',
          'Customizable student activities with real-time insights via Formative',
        ],
        includedText: '',
        isPreferred: false,
        colorPreferred: '',
      },
      {
        id: 'essentials',
        title: 'Newsela Essentials',
        subtitle:
          'Cover current events and breaking news, make real-world connections to lessons, and engage students as readers.',
        buttonText: 'Connect with our team',
        buttonUrl: 'https://go.newsela.com/contact',
        openInNewTab: false,
        features: [
          'Library of 8,000+ current events articles and breaking news coverage',
          'Class and student reading trends data available in real time',
          'Admin content controls to manage content visibility and expectations',
          'Teacher and administrator chat support with the Newsela team',
        ],
        includedText: 'Everything included in Newsela Lite, plus:',
        isPreferred: false,
        colorPreferred: '',
      },
      {
        id: 'ela',
        title: ' Newsela ELA',
        subtitle:
          'Drive meaningful student outcomes with content-rich literacy instruction.',
        buttonText: 'Connect with our team',
        buttonUrl: 'https://go.newsela.com/contact',
        openInNewTab: false,
        features: [
          'Fiction and informational texts for the ELA classroom',
          'Interactive ELA videos with searchable transcripts',
          'Knowledge-building and engagement-driving collections, like Novel Studies and ELA in the Real World',
          'Pre-built resources aligned to popular ELA curricula, like Amplify ELA and Wonders',
          'ELA lesson suggestions and planning tools',
          'Power Words vocabulary with practice activities',
          'Add-ons available for purchase to enhance your experience',
        ],
        includedText: 'Everything included in Newsela Essentials, plus:',
        isPreferred: true,
        colorPreferred: '#DE471A',
      },
      {
        id: 'social-studies',
        title: 'Newsela Social Studies',
        subtitle:
          'Prepare the next generation of citizens with relevant and responsive materials, all in one place.',
        buttonText: 'Connect with our team',
        buttonUrl: 'https://go.newsela.com/contact',
        openInNewTab: false,
        features: [
          'Accessible primary sources, speeches, biographies, and other nonfiction social studies texts',
          'Interactive social studies videos with searchable transcripts',
          'Curated collections aligned to US History, World History, Civics, Economics, and Ancient Civilizations',
          'Pre-built resources aligned to popular social studies curricula',
          'Inquiry-based lesson resources and planning tools',
          'Add-ons available for purchase to enhance your experience',
        ],
        includedText: 'Everything included in Newsela Essentials, plus:',
        isPreferred: true,
        colorPreferred: '#DA5083',
      },
      {
        id: 'science',
        title: 'Newsela Science',
        subtitle:
          'Integrate science and literacy to inspire the scientist in every learner.',
        buttonText: 'Connect with our team',
        buttonUrl: 'https://go.newsela.com/contact',
        openInNewTab: false,
        features: [
          'Accessible science texts, like real-world phenomena and science core idea explainers',
          'Interactive science videos with searchable transcripts',
          'Curated collections to support both required science topics and engaging supplemental lessons',
          'Pre-built resources aligned to popular science curricula, like Mystery Science',
          'NGSS-aligned lesson suggestions and Claim-Evidence-Reasoning activities',
          'Add-ons available for purchase to enhance your experience',
        ],
        includedText: 'Everything included in Newsela Essentials, plus:',
        isPreferred: true,
        colorPreferred: '#8B29EB',
      },
      {
        id: 'bronze',
        title: 'Formative Bronze',
        subtitle:
          'Get started with Formative, with student activities and real-time results.',
        buttonText: 'Sign up for free',
        buttonUrl: 'https://app.formative.com/signup',
        openInNewTab: true,
        features: [
          'Create unlimited lessons, assignments, and assessments',
          'Real-time student tracking',
          'Create and manage classes',
          'Basic integrations and embedding',
        ],
        includedText: '',
        isPreferred: false,
        colorPreferred: '',
      },
      {
        id: 'silver',
        title: 'Formative Silver',
        subtitle:
          'Add more activities, assignment, and grading features to your Formative experience.',
        buttonText: 'Upgrade',
        buttonUrl: 'https://app.formative.com/signup',
        openInNewTab: true,
        features: [
          'Advanced question types',
          'Grading and feedback tools',
          'Advanced assignment settings',
        ],
        includedText: 'Everything included in Bronze, plus:',
        isPreferred: false,
        colorPreferred: '',
      },
      {
        id: 'gold',
        title: 'Formative Gold',
        subtitle:
          'Support a range of instruction and assessment needs at your school or district, with collaboration and common assessment tools and admin reporting.',
        buttonText: 'Request a quote',
        buttonUrl: 'https://www.formative.com/form/request-a-quote',
        openInNewTab: true,
        features: [
          'Collaboration',
          'Unlimited data tracking',
          'Organization-wide Standards progress over time',
          'Results by demographics, SpEd, ELL, +',
          'Common assessment',
          'Organization-wide private library',
          'Anti-cheating features',
          'Student accommodations',
          'Team management and reports',
          'Gold support and training',
          'Advanced LMS integrations',
          'SIS nightly syncs',
          'Add-on: Licensed item bank',
          'Add-on: Lock down browser',
        ],
        includedText: 'Everything included in Silver, plus:',
        isPreferred: true,
        colorPreferred: '#DF9C2B',
      },
    ];

    setProductData(data);
  }, []);

  return productData;
};

export default useProductComparisonData;
