import React, { useRef, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import classnames from 'classnames';
import styles from './zoetrope.module.scss';

export default function Zoetrope ({ src, poster, className, autoplay=false, muted=false, loop=false }) {
  const [containerRef, inView] = useInView();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const videoRef = useRef(null);

  /*
   *   Handle mask click
   */
  function handleMaskClick () {
    if (!videoRef.current) return;
    videoRef.current.play();
    setIsPlaying(true);
  }

  /*
   *   Use Effect
   */
  useEffect(() => {
    videoRef.current.volume = 0.25;
    if (!isPlaying) videoRef.current.pause();
  }, [isPlaying]);

  useEffect(() => {
    if (!inView) {
      setIsPlaying(false);
    }
  }, [inView]);

  useEffect(() => {
    requestAnimationFrame(() => setIsVisible(true));
  }, []);

  /*
   *   Return
   */
  return (
    <div
      ref={containerRef}
      className={classnames(className, styles.container, {
        [styles.is_playing]: isPlaying,
        [styles.visible]: isVisible
      })}
    >
      <video 
        ref={videoRef} 
        src={src} width='100%' 
        onClick={() => setIsPlaying(false)} 
        playsInline 
        autoPlay={autoplay}
        muted={muted}
        loop={loop} 
      />
      <div className={styles.mask} onClick={handleMaskClick}>
        {poster && <img src={poster} alt='' />}
      </div>
    </div>
  );
}
