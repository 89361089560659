import React from 'react';
import classnames from 'classnames';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { useInView } from 'react-intersection-observer';
import colorTheme from 'helpers/colorTheme';
import SmartLink from 'components/smartLink';
import TextWithBr from 'components/textWithBr';
import RichText from 'components/richText';
import styles from './checklistWithQuotes.module.scss';

export default function ChecklistWithQuotes({
  title,
  intro,
  checklist,
  quotes,
  backgroundColor,
  cta,
  buttonText,
  buttonUrl,
  sectionId,
}) {
  const [containerRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const quoteEls = quotes
    ? quotes.map((q) => (
        <blockquote
          key={q.id}
          className={classnames(styles.quote, {
            [styles.quote__no_photo]: !q.photo,
          })}
        >
          {q.photo && (
            <div className={styles.quote__photo}>
              {q.photo && <Img fixed={q.photo.fixed} alt={q.photo.title} />}
            </div>
          )}
          <div className={styles.author} role="heading" aria-level="2">
            {q.name}
            {q.location && (
              <TextWithBr
                className={styles.quote__location}
                content={`${q.location}`}
              />
            )}
          </div>
          {q.quote && (
            <RichText className={styles.quote__text} content={q.quote.json} />
          )}
        </blockquote>
      ))
    : null;

  return (
    <section
      id={sectionId}
      ref={containerRef}
      style={colorTheme('background-color', backgroundColor)}
      className={classnames(styles.container, styles[backgroundColor], {
        [styles.revealed]: inView,
        [styles.no_quotes]: !quotes,
      })}
    >
      <div className={styles.inner}>
        <h2 className={styles.title}>{title}</h2>
        <h3 className={styles.subtitle}>{intro}</h3>

        <div className={styles.content}>
          {checklist && (
            <RichText className={styles.checklist} content={checklist.json} />
          )}
          <div className={styles.quotes}>{quoteEls}</div>
        </div>
        {cta && <div className={styles.cta}>{cta}</div>}
        {buttonText && (
          <SmartLink to={buttonUrl} className={styles.button}>
            {buttonText}
          </SmartLink>
        )}
      </div>
    </section>
  );
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const checklistWithQuotesFragment = graphql`
  fragment ChecklistWithQuotesFragment on Node {
    ... on ContentfulChecklistWithQuotes {
      id
      sectionId
      title
      intro
      backgroundColor
      cta
      buttonText
      buttonUrl
      checklist {
        json
      }
      quotes {
        id
        name
        location
        quote {
          json
        }
        photo {
          title
          fixed(height: 150) {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
        }
      }
    }
  }
`;
