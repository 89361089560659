import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import forceLayout from 'helpers/forceLayout';
import Img from 'gatsby-image/withIEPolyfill';
import SmartLink from 'components/smartLink';
import Roundabout from 'components/roundabout';
import RichText from 'components/richText';
import TextWithBr from 'components/textWithBr';
import { ReactComponent as LeftCaretIcon } from 'images/icon-caret-left.svg';
import { ReactComponent as RightCaretIcon } from 'images/icon-caret-right.svg';
import styles from './heroBackgroundSlideshow.module.scss';

export default function HeroBackgroundSlideshow({
  title,
  subtitle,
  buttonText,
  buttonUrl,
  backgroundImages,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  function advance() {
    const next =
      currentSlide >= backgroundImages.length - 1 ? 0 : currentSlide + 1;
    setCurrentSlide(next);
  }

  function retreat() {
    const prev =
      currentSlide === 0 ? backgroundImages.length - 1 : currentSlide - 1;
    setCurrentSlide(prev);
  }

  /*
   *   Use Effect
   */
  useEffect(() => {
    forceLayout();
    requestAnimationFrame(() => {
      setIsLoaded(true);
    });
  }, [setIsLoaded]);

  const slideshow =
    backgroundImages != null && backgroundImages.length > 1 ? (
      <Roundabout
        className={styles.slideshow}
        currentSlide={currentSlide}
        wrapAround={true}
        onSlideChange={(index) => setCurrentSlide(index)}
      >
        {backgroundImages.map((image) => (
          <Img
            key={image.file.url}
            style={{ width: '50%', minHeight: '100%' }}
            className={styles.slideshow_image}
            fluid={image.fluid}
          />
        ))}
      </Roundabout>
    ) : (
      <div className={styles.slideshow}>
        <Img
          style={{ width: '100%' }}
          className={styles.slideshow_image}
          fluid={
            backgroundImages != null && backgroundImages.length > 0
              ? backgroundImages[0].fluid
              : null
          }
        />
      </div>
    );

  const breadcrumbs =
    backgroundImages != null
      ? backgroundImages.map((image, i) => (
          <button
            key={image + i}
            className={classnames(styles.breadcrumb, {
              [styles.current]: currentSlide === i,
            })}
            onClick={() => setCurrentSlide(i)}
          ></button>
        ))
      : null;

  /*
   *   Render
   */
  return (
    <section
      className={classnames(styles.container, {
        [styles.loaded]: isLoaded,
      })}
    >
      {backgroundImages?.length > 1 && (
        <div className={styles.navigation}>
          <button
            className={styles.nav_button}
            onClick={retreat}
            aria-label="Previous slide"
          >
            <LeftCaretIcon />
          </button>
          <button
            className={styles.nav_button}
            onClick={advance}
            aria-label="Next slide"
          >
            <RightCaretIcon />
          </button>
        </div>
      )}
      <div className={styles.inner}>
        <div className={styles.text}>
          {title && (
            <TextWithBr className={styles.title} tag="h1" content={title} />
          )}
          {subtitle && (
            <h2 className={styles.subtitle}>
              <RichText content={subtitle.json} />
            </h2>
          )}
          {buttonText && (
            <SmartLink to={buttonUrl} className={classnames(styles.button)}>
              {buttonText}
            </SmartLink>
          )}
        </div>
      </div>
      {backgroundImages?.length && slideshow}
      {backgroundImages?.length > 1 && (
        <div className={styles.breadcrumbs}>{breadcrumbs}</div>
      )}
    </section>
  );
}
