import removeBr from './removeBr';

export default function slugify(stringToFormat: string) {
  const whitelist =
    '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ/-_ ';
  const strArray = removeBr(stringToFormat)
    .trim()
    .replace(' / ', '/')
    .replace(' /', '/')
    .replace('/ ', '/')
    .replace(/^\//, '')
    .replace(/\s+/g, '-')
    .split('');

  const slug = encodeURI(
    strArray.filter((c) => whitelist.includes(c)).join('')
  );

  return slug;
}
