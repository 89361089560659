import React from 'react';
import Header from './header';
import Footer from './footer';
import PopupCTA from 'components/popupCTA';
import styles from './layout.module.scss';

class Layout extends React.Component {
  render() {
    const { children, location, data } = this.props;

    //If page is a normal page; use contetfulPage banner field, else, it could be a blog page;
    //so use the contentfulBlogPost banner field. Maybe we should add this logif for pressRelease?
    //If it is none of these pages, it will send null and header will use siteSettings by default.
    const banner =
      data?.contentfulPage?.banner || data?.contentfulBlogPost?.banner;

    return (
      <div className={styles.container}>
        <main>
          <Header location={location} banner={banner} />
          {children}
          <Footer />
        </main>
      </div>
    );
  }
}

export default Layout;
