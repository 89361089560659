/* eslint-disable no-prototype-builtins */
import React from 'react';
import { INLINES, MARKS, BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import classnames from 'classnames';
import useVidyard from '../hooks/useVidyard';
import LazyImage from './lazyImage';
import EmbeddedTable from './embeddedTable';
import SmartLink from './smartLink';
import { Document } from '@contentful/rich-text-types';
//Import components for embedded entries
import CTA from './cta';
import Advertisement from './advertisement';
import TextAndGraphic from './textAndGraphic';
import MultipurposeContainer from './multipurposeContainer';
import TabbedGallery from './tabbedGallery';
import Container from './container';
import CustomList from './customList';
import LongText from './longText';
import CodeBlock from './codeBlock';
import styles from './richText.module.scss';

interface RichTextProps {
  content?: Document;
  html?: string;
  className?: string;
  innerClassName?: string;
}

const CUSTOM_BLOCKS = {
  TABLE: 'table',
  TABLE_ROW: 'table-row',
  TABLE_CELL: 'table-cell',
  TABLE_HEADER_CELL: 'table-header-cell',
  ADVERTISEMENT_CARD: 'advertisement-card',
};

const hasLinkFormat = (text: string): boolean => {
  const pattern = /\[.*\]\(.*\)/;
  return pattern.test(text);
};

function getTextInParentheses(text: string) {
  // extract text from parentheses
  const pattern = /\[([^\]]+)\]\(([^)]+)\)/;
  const coincidence = text.match(pattern);

  const completeCoincidence = coincidence[0];
  const newText = coincidence[1];
  const linkTarget = coincidence[2];

  const linkText = text.replace(completeCoincidence, newText);

  return coincidence && { linkText, linkTarget };
}

// Map Contentful content types to components
const embeddedComponents = {
  cta: CTA,
  textAndGraphic: TextAndGraphic,
  multipurposeContainer: MultipurposeContainer,
  tabbedGallery: TabbedGallery,
  container: Container,
  customList: CustomList,
  longText: LongText,
  codeBlock: CodeBlock,
};

const fieldsAllowedToFormat = [
  'contentBlocks',
  'tabs',
  'images',
  'icon',
  'items',
];

const formatContent = (node) => {
  const contentType = node?.sys?.contentType?.sys?.id;
  const componentInfo = node?.fields;
  const EmbeddedComponent = embeddedComponents[contentType];

  let data = Object.keys(componentInfo).reduce((acc, fieldName) => {
    let fieldToFormat = componentInfo[fieldName]['en-US'];

    if (fieldToFormat.hasOwnProperty('fields')) {
      fieldToFormat = Object.keys(fieldToFormat.fields).reduce(
        (accum, propName) => {
          accum[propName] = fieldToFormat.fields[propName]['en-US'];

          return accum;
        },
        {}
      );
    }

    if (fieldsAllowedToFormat.includes(fieldName) && fieldToFormat.length > 0) {
      fieldToFormat = fieldToFormat.map((element) => formatContent(element));
    }

    acc[fieldName] = fieldToFormat;

    return acc;
  }, {});

  data = { ...data, componentContentType: contentType };

  if (EmbeddedComponent) return <EmbeddedComponent {...data} />;
  if (data) return data;

  return null;
};

const RichText: React.FC<RichTextProps> = ({
  content,
  html,
  className,
  innerClassName,
}) => {
  const isTemplate = className === 'template';

  if (content && !html) {
    const options = {
      renderMark: {
        [MARKS.CODE]: (text) => <sup>{text}</sup>,
      },
      renderNode: {
        [BLOCKS.PARAGRAPH]: (_node, children) => (
          <p className={classnames({ [styles.rteParagraph]: isTemplate })}>
            {children}
          </p>
        ),
        [BLOCKS.HEADING_1]: (_node, children) => (
          <h1 className={classnames({ [styles.rteHeading1]: isTemplate })}>
            {children}
          </h1>
        ),
        [BLOCKS.HEADING_2]: (_node, children) => (
          <h2 className={classnames({ [styles.rteHeading2]: isTemplate })}>
            {children}
          </h2>
        ),
        [BLOCKS.HEADING_3]: (_node, children) => (
          <h3 className={classnames({ [styles.rteHeading3]: isTemplate })}>
            {children}
          </h3>
        ),
        [BLOCKS.HEADING_4]: (_node, children) => (
          <h4 className={classnames({ [styles.rteHeading4]: isTemplate })}>
            {children}
          </h4>
        ),
        [BLOCKS.HEADING_5]: (_node, children) => (
          <h5 className={classnames({ [styles.rteHeading5]: isTemplate })}>
            {children}
          </h5>
        ),
        [BLOCKS.HEADING_6]: (_node, children) => (
          <h6 className={classnames({ [styles.rteHeading6]: isTemplate })}>
            {children}
          </h6>
        ),
        [CUSTOM_BLOCKS.TABLE_ROW]: (_node, children) => <tr>{children}</tr>,
        [CUSTOM_BLOCKS.TABLE_CELL]: (_node, children) => <td>{children}</td>,
        [CUSTOM_BLOCKS.TABLE_HEADER_CELL]: (_node, children) => (
          <td style={{ backgroundColor: 'rgba(229,229,229, 0.4)' }}>
            <b>{children}</b>
          </td>
        ),
        [CUSTOM_BLOCKS.TABLE]: (_node, children) => (
          <table>
            <tbody>{children}</tbody>
          </table>
        ),
        [CUSTOM_BLOCKS.ADVERTISEMENT_CARD]: (node) => {
          const data = {
            ...node.data,
            description: node.data.description?.json,
          };

          return <Advertisement {...data} />;
        },
        [INLINES.HYPERLINK]: (node, children) => {
          useVidyard(node.data.uri.includes('play.vidyard.com'));

          if (node.data.uri.includes('play.vidyard.com')) {
            const id = node.data.uri
              .substr(node.data.uri.lastIndexOf('/') + 1)
              .replace('.jpg', '');
            return (
              <img
                style={{ width: '100%', margin: 'auto', display: 'block' }}
                className="vidyard-player-embed"
                src={node.data.uri}
                data-uuid={id}
                data-v="4"
                data-type="inline"
                alt=""
              />
            );
          } else {
            return (
              <SmartLink
                className={classnames({ [styles.link]: isTemplate })}
                to={node.data.uri}
              >
                {children}
              </SmartLink>
            );
          }
        },
        // Map Contentful content types to components
        'embedded-entry-inline': (node) => formatContent(node?.data?.target),
        'embedded-asset-block': (node) =>
          node.data.target.fields ? (
            <LazyImage
              src={`${node.data.target.fields.file?.['en-US'].url}`}
              height={
                node.data.target.fields.file?.['en-US'].details.image?.height
              }
              width={node.data.target.fields.file['en-US'].details.image?.width}
              alt={node.data.target.fields.title['en-US']}
              className={classnames({ [styles.solutions]: isTemplate })}
            />
          ) : null,
        'embedded-entry-block': (node) => {
          if (
            node?.data?.target?.fields?.table &&
            node?.data?.target?.fields?.table['en-US']?.tableData
          ) {
            return <EmbeddedTable rows={node.data.target.fields} />;
          } else {
            return formatContent(node?.data?.target);
          }
        },
        'asset-hyperlink': (node) => {
          const fileProps = node?.data?.target?.fields;

          return (
            <span>
              <LazyImage
                src={`${fileProps.file?.['en-US'].url}`}
                height={fileProps.file?.['en-US'].details.image?.height}
                width={fileProps.file['en-US'].details.image?.width}
                alt={fileProps.title['en-US']}
              />
            </span>
          );
        },
      },
      renderText: (text) => {
        return text.split('\n').reduce((children, textSegment, index) => {
          let textToDisplay = textSegment;
          if (hasLinkFormat(textToDisplay)) {
            const { linkText, linkTarget } =
              getTextInParentheses(textToDisplay);

            if (linkText && linkTarget) {
              textToDisplay = <p id={linkTarget}>{linkText}</p>;
            }
          }
          return [...children, index > 0 && <br key={index} />, textToDisplay];
        }, []);
      },
    };
    // @ts-ignore
    const markup = documentToReactComponents(content, options);

    return (
      <div className={className}>
        <div
          className={classnames(innerClassName, {
            [styles.markupContainer]: isTemplate,
          })}
        >
          {markup}
        </div>
      </div>
    );
  } else if (html) {
    return (
      <div className={className} dangerouslySetInnerHTML={{ __html: html }} />
    );
  }

  return null;
};

export default RichText;
