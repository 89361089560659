import React, { useState } from 'react';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';
import colorTheme from 'helpers/colorTheme';
import SmartLink from 'components/smartLink';
import RichText from 'components/richText';
import Roundabout from 'components/roundabout';
import TextWithBr from 'components/textWithBr';
import { ReactComponent as LeftCaretIcon } from 'images/icon-caret-left.svg';
import { ReactComponent as RightCaretIcon } from 'images/icon-caret-right.svg';
import styles from './quoteCarousel.module.scss';

export default function QuoteCarousel({
  title,
  buttonText,
  buttonUrl,
  quotes,
  backgroundColor,
  sectionId,
}) {
  const [containerRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const [currentSlide, setCurrentSlide] = useState(1);

  /*
   *   Quotes
   */
  const quoteEls = quotes.map((q) => (
    <blockquote key={q.id} className={styles.quote}>
      {q.quote && <RichText className={styles.text} content={q.quote.json} />}
      <footer className={styles.footer}>
        {q.name && <div className={styles.author}>{q.name}</div>}
        {q.location && (
          <TextWithBr className={styles.location} content={q.location} />
        )}
      </footer>
    </blockquote>
  ));

  /*
   *   Pagination els
   */
  const paginationEls = quotes.map((q, i) => (
    <button
      key={q.id}
      className={classnames(styles.pagination_button, {
        [styles.current]: i === currentSlide,
      })}
      style={{
        maxWidth: `${100 / quotes.length}%`,
      }}
      onClick={() => setCurrentSlide(i)}
    >
      Go to slide {i}
    </button>
  ));

  function advance() {
    const next =
      currentSlide >= quotes.length - 1 ? 0 : currentSlide + 1;
    setCurrentSlide(next);
  }

  function retreat() {
    const prev = currentSlide === 0 ? quotes.length - 1 : currentSlide - 1;
    setCurrentSlide(prev);
  }

  /*
   *   Return
   */
  return (
    <section
      id={sectionId}
      ref={containerRef}
      className={classnames(styles.container, { [styles.revealed]: inView })}
    >
      <div
        style={colorTheme('background-color', backgroundColor)}
        className={styles.background}
      ></div>
      <div className={styles.inner}>
        <h2 className={styles.title}>{title}</h2>
        {buttonUrl && (
          <SmartLink
            className={styles.button}
            to={buttonUrl}
            aria-label={buttonText}
          >
            {buttonText}
          </SmartLink>
        )}
        {quotes.length > 2 ? (
          <Roundabout
            className={styles.carousel}
            maxSlideWidth="850px"
            currentSlide={currentSlide}
            onSlideChange={(index) => setCurrentSlide(index)}
          >
            {quoteEls}
          </Roundabout>
        ) : (
          <div className={styles.two_up}>{quoteEls}</div>
        )}
        {quotes.length > 2 && (
          <div className={styles.pagination}>{paginationEls}</div>
        )}
        {quotes.length > 2 && (
          <div className={styles.arrows}>
            <button
              className={styles.arrow}
              onClick={retreat}
              aria-label="Previous slide"
            >
              <LeftCaretIcon />
            </button>
            <button
              className={styles.arrow}
              onClick={advance}
              aria-label="Next slide"
            >
              <RightCaretIcon />
            </button>
          </div>
        )}
      </div>
    </section>
  );
}
