import React from 'react';
import { graphql } from 'gatsby';
import { generateBlocks } from '../components';

interface ContentBlocksProps {
  __typename: string;
  format?: string;
  layout?: string;
  id?: string;
  [key: string]: unknown;
}

export interface TabProps {
  tabName: string;
  contentBlocks: [ContentBlocksProps] | any;
}

export default function Tabs({ tab }: { tab: TabProps }) {
  const blocks = generateBlocks({
    blocks: tab.contentBlocks,
    location: null,
    page: null,
  });

  return <section>{blocks}</section>;
}

export const tabFragment = graphql`
  fragment tabFragment on Node {
    ... on ContentfulTab {
      tabName
      contentBlocks {
        ... on Node {
          __typename
          id
          ... on ContentfulHero {
            ...HeroFragment
          }
          ... on ContentfulLogoLine {
            ...LogoLineFragment
          }
          ... on ContentfulCallout {
            ...CalloutFragment
          }
          ... on ContentfulTabbedGallery {
            ...TabbedGalleryFragment
          }
          ... on ContentfulTabbedContent {
            ...TabbedContentFragment
          }
          ... on ContentfulChecklistWithQuotes {
            ...ChecklistWithQuotesFragment
          }
          ... on ContentfulExampleContent {
            ...ExampleContentFragment
          }
          ... on ContentfulTextAndGraphic {
            ...TextAndGraphicFragment
          }
          ... on ContentfulContentSquares {
            ...ContentSquaresFragment
          }
          ... on ContentfulTestimonialCarousel {
            ...TestimonialCarouselFragment
          }
          ... on ContentfulTeamMembers {
            ...TeamMembersFragment
          }
          ... on ContentfulVideoSection {
            ...VideoSectionFragment
          }
          ... on ContentfulPressReleases {
            ...PressReleasesFragment
          }
          ... on ContentfulCta {
            ...CTAFragment
          }
          ... on ContentfulEmbeddedWidget {
            ...EmbeddedWidgetFragment
          }
          ... on ContentfulLongText {
            ...LongTextFragment
          }
          ... on ContentfulPillars {
            ...PillarsFragment
          }
          ... on ContentfulComplexText {
            ...ComplexTextFragment
          }
          ... on ContentfulSlideDeck {
            ...SlideDeckFragment
          }
          ... on ContentfulMultipurposeContainer {
            ...MultipurposeContainerFragment
          }
        }
      }
    }
  }
`;
