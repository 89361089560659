import React, { forwardRef, Ref, CSSProperties } from 'react';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import { useLocation } from '@reach/router';

const redirects: [string, string][] = [
  ['solutions/funding-newsela', 'whats-new/funding-newsela'],
  ['solutions/ela', 'products/ela'],
  ['solutions/social-studies', 'products/social-studies'],
  ['solutions/science', 'products/science'],
  ['solutions/essentials', 'products/essentials'],
  ['solutions/sel', 'products/sel'],
  ['solutions/custom-collections', 'products/custom-collections'],
  ['solutions/compare', 'products/product-comparison'],
  ['content/access-and-equity', 'solutions/access-and-equity'],
  [
    'solutions/integration-partners',
    'solutions/integrations-and-interoperability',
  ],
  ['resources/arizona', 'solutions/for-your-state/arizona'],
  ['resources/connecticut', 'solutions/for-your-state/connecticut'],
  ['resources/florida', 'solutions/for-your-state/florida'],
  ['resources/illinois', 'solutions/for-your-state/illinois'],
  ['resources/indiana', 'solutions/for-your-state/indiana'],
  ['resources/massachusetts', 'solutions/for-your-state/massachusetts'],
  ['resources/mississippi', 'solutions/for-your-state/mississippi'],
  ['resources/new-jersey', 'solutions/for-your-state/new-jersey'],
  ['resources/north-carolina', 'solutions/for-your-state/north-carolina'],
  ['resources/ohio', 'solutions/for-your-state/ohio'],
  ['resources/oregon', 'solutions/for-your-state/oregon'],
  ['resources/pennsylvania', 'solutions/for-your-state/pennsylvania'],
  ['resources/texas', 'solutions/for-your-state/texas'],
  ['resources/virginia', 'solutions/for-your-state/virginia'],
  ['resources/michigan', 'solutions/for-your-state/michigan'],
  ['resources/new-york', 'solutions/for-your-state/new-york'],
  ['resources/maine', 'solutions/for-your-state/maine'],
  ['resources/california', 'solutions/for-your-state/california'],
  ['resources/west-virginia-ela', 'solutions/for-your-state/west-virginia-ela'],
  ['services/training-and-support', 'training/professional-learning'],
  ['services/implementation-tips', 'training/implementation-tips'],
  ['services/certified-educator-program', 'training/certifications'],
  ['featured-states', 'solutions/for-your-state'],
  [
    'solutions/for-your-state/maryland-social-studies',
    'solutions/for-your-state/maryland',
  ],
];

function addTrailingSlash(str: string): string {
  if (str && str.includes('#')) return str;
  return str && str.endsWith('/') ? str : str + '/';
}

function removePreceedingSlash(str: string): string {
  return str.replace(/^\//, '');
}

function setSlashes(str: string): string {
  return str && addTrailingSlash(removePreceedingSlash(str));
}

function checkForNewselaDomain(url: string): boolean {
  const regex = /\b(?:https?:\/\/)?(?:\w+\.)?newsela\.\w+\b/;
  return regex.test(url);
}

function addSlashesAndRedirects(str: string): string {
  return redirects.reduce((acc, curr) => {
    return setSlashes(curr[0]) === acc ? setSlashes(curr[1]) : acc;
  }, setSlashes(str));
}

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]', {
    offset: 270,
    speed: 10,
  });
}

interface SmartLinkProps extends GatsbyLinkProps<any> {
  to: string;
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
}

const SmartLink = forwardRef(
  (props: SmartLinkProps, ref: Ref<HTMLAnchorElement>): JSX.Element => {
    const location = useLocation();
    const { to, ...rest } = props;

    // If the link is external, open in a new tab
    if (to?.startsWith('mailto:') || to?.includes('.pdf')) {
      return (
        <a ref={ref} {...rest} rel="noreferrer" target="_blank" href={to} />
      );
    } else if (to?.startsWith('http')) {
      if (checkForNewselaDomain(to)) {
        return <a ref={ref} {...rest} rel="noreferrer" href={to} />;
      }

      return (
        <a ref={ref} {...rest} target="_blank" rel="noreferrer" href={to} />
      );
    } else if (to?.startsWith('#')) {
      // If the link is a hash, scroll to the element
      return (
        //@ts-ignore https://github.com/gatsbyjs/gatsby/issues/16682
        <GatsbyLink ref={ref} {...rest} to={`${location.pathname}${to}`} />
      );
    }

    // If the link is internal, add the prefix
    const prefixedTo =
      to === '/' ? '/' : `/about/${addSlashesAndRedirects(to)}`;
    //@ts-ignore https://github.com/gatsbyjs/gatsby/issues/16682
    return <GatsbyLink ref={ref} {...rest} to={prefixedTo} />;
  }
);

SmartLink.defaultProps = {
  to: '/',
};

export default SmartLink;
