import React, { forwardRef, useState } from 'react';
import classnames from 'classnames';
import styles from './controlledInput.module.scss';

const ControlledInput = forwardRef(
  (
    { onChange, className, value, ariaLabel, tag, updateOn, onBlur, ...rest },
    ref
  ) => {
    const [currentVal, setCurrentVal] = useState(value);
    const El = tag === 'textarea' ? 'textarea' : 'input';

    /*
     *   Change Handler
     */
    function handleChange(evt) {
      if (updateOn === 'blur') {
        setCurrentVal(evt.target.value);
      } else {
        onChange(evt.target.value);
      }
    }

    /*
     *   Handle Blur
     */
    function handleBlur(evt) {
      if (updateOn === 'blur') {
        submit(evt.target.value);
      } else {
        onChange(evt.target.value);
      }

      if (onBlur) onBlur(evt);
    }

    /*
     *   Submit change
     */
    function submit(val) {
      onChange(val);
    }

    return (
      <El
        ref={ref}
        className={classnames(styles.input, className)}
        onChange={handleChange}
        onBlur={handleBlur}
        value={updateOn === 'blur' ? currentVal : value}
        aria-label={ariaLabel}
        type={tag === 'textarea' ? null : tag}
        {...rest}
      />
    );
  }
);
export default ControlledInput;

ControlledInput.defaultProps = {
  className: '',
  tag: 'text',
  value: '',
  ariaLabel: null,
  updateOn: 'change',
};
