import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { useTransition, animated } from 'react-spring';
import classnames from 'classnames';
import Mousetrap from 'mousetrap';
import get from 'lodash/get';
import { NavItem, NavDropdownItem } from './mainNav';
import useWindowSize from 'hooks/useWindowSize';
import RichText from 'components/richText';
import SmartLink from 'components/smartLink';
import ClassCodeEntry from 'components/classCodeEntry';
import { useBanner } from '../contexts/bannerContext';
import { ReactComponent as Logo } from 'images/logo.svg';
import { ReactComponent as Arrow } from 'images/icon-blue-arrow.svg';
import { ReactComponent as Logomark } from 'images/logomark.svg';
import { ReactComponent as XIcon } from 'images/icon-x.svg';
import { ReactComponent as DownCaretIcon } from 'images/icon-caret-down-alt.svg';
import styles from './header.module.scss';

function trimSlashes(str) {
  return str ? str.replace(/^\/|\/$/g, '') : str;
}

function MobileNav({ children, shouldRender, containerRef, className }) {
  const transitions = useTransition(shouldRender, null, {
    config: { mass: 1, tension: 210 },
    from: { transform: 'translate3d(-100%,0,0)' },
    enter: { transform: 'translate3d(0%,0,0)' },
    leave: { transform: 'translate3d(-100%,0,0)' },
  });

  useEffect(() => {
    if (shouldRender && containerRef.current) containerRef.current.focus();
  }, [containerRef, shouldRender]);

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.nav
          tabIndex={-1}
          ref={containerRef}
          key={key}
          style={props}
          className={classnames(styles.mobile_menu, className)}
        >
          {children}
        </animated.nav>
      )
  );
}

export default function Header({ location, banner }) {
  const [isHoveringRightMenu, setIsHoveringRightMenu] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState(null);
  const updateBannerVisibility = get(
    useBanner(),
    'updateBannerVisibility',
    () => null
  );

  /*
   *   Query main nav items
   */
  const data = useStaticQuery(graphql`
    query NavQuery {
      contentfulNavigationMenu(contentful_id: { eq: "lmA7oQv6PCqBkQcSpJ3bx" }) {
        links {
          ... on ContentfulNavigationLink {
            ...NavigationLinkFragment
          }
          sublinks {
            ... on ContentfulNavigationLink {
              ...NavigationLinkFragment
            }
          }
          mainNavigationReplacement {
            ... on ContentfulNavigationLink {
              ...NavigationLinkFragment
            }
          }
          featuredContent {
            ... on ContentfulNavigationLink {
              ...NavigationLinkFragment
            }
          }
          navigationFooterLink {
            ... on ContentfulNavigationLink {
              ...NavigationLinkFragment
            }
          }
        }
      }
      contentfulSiteSettings(contentful_id: { eq: "6OlY495jBkK7eqij0SOSY8" }) {
        hideHeaderOn {
          slug
        }
        hideAnnouncementBarOn {
          slug
        }
        banner {
          id
          announcementText {
            json
          }
          backgroundColor
          show
        }
        card1Title
        card1Text {
          card1Text
        }
        card1ButtonText
        card1ButtonUrl
        card2Title
        card2Text {
          card2Text
        }
        card2ButtonText
        card2ButtonUrl
        card3Title
        card3Text {
          card3Text
        }
        card3ButtonText
        card3ButtonUrl
      }
    }
  `);

  const onMouseEnterHandler = () => {
    setIsHoveringRightMenu(true);
  };

  const onMouseLeaveHandler = () => {
    setIsHoveringRightMenu(false);
  };

  const settings = data.contentfulSiteSettings;
  const bannerData = banner || settings.banner;

  const hideHeader = settings.hideHeaderOn
    ? settings.hideHeaderOn
        .map((p) => trimSlashes(p.slug))
        .includes(trimSlashes(location.pathname.replace('/about/', '')))
    : false;

  // Tech debt: Add Banner logic for BlogPost
  const hideAnnouncementBar = settings.hideAnnouncementBarOn
    ? settings.hideAnnouncementBarOn
        .map((p) => trimSlashes(p.slug))
        .includes(trimSlashes(location.pathname.replace('/about/', '')))
    : false;

  const windowSize = useWindowSize(100);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const hamburgerRef = useRef();
  const mobileMenuRef = useRef();
  const skipLinkRef = useRef();
  const [isLoaded, setIsLoaded] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [showAnnouncement, setShowAnnouncement] = useState(bannerData?.show);
  const [showAnnouncementDrawer, setShowAnnouncementDrawer] = useState(false);
  const announcementContainerRef = useRef(null);

  const closeAnnouncement = () => {
    setShowAnnouncement(false);
    updateBannerVisibility(false);
  };

  function toggleAnnouncementDrawer() {
    setShowAnnouncementDrawer(!showAnnouncementDrawer);
  }

  const closeAnnouncementDrawer = useCallback(() => {
    if (setShowAnnouncementDrawer) setShowAnnouncementDrawer(false);
  }, []);

  function closeMobileMenu(evt) {
    if (
      evt.key !== 'Escape' &&
      (!mobileMenuRef.current || mobileMenuRef.current.contains(evt.target))
    )
      return;
    setMobileMenuIsOpen(false);
    setSelectedNavItem(null);
    Mousetrap.unbind(['esc']);
    if (hamburgerRef.current) hamburgerRef.current.focus();
  }

  function toggleMobileMenu() {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
    if (!mobileMenuIsOpen) Mousetrap.bind(['esc'], closeMobileMenu);
  }

  const onScroll = useCallback(() => {
    const percentScrolled =
      (window.scrollY + window.innerHeight) / document.body.clientHeight;
    if (percentScrolled > 0.5 && !showBackToTop) {
      setShowBackToTop(true);
    } else if (percentScrolled <= 0.5 && showBackToTop) {
      setShowBackToTop(false);
    }
  }, [showBackToTop]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    setTimeout(() => {
      setIsLoaded(true);
    }, 1000);

    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  useEffect(() => {
    if (mobileMenuIsOpen) {
      document.addEventListener('click', closeMobileMenu);
    } else {
      document.removeEventListener('click', closeMobileMenu);
    }

    return () => document.removeEventListener('click', closeMobileMenu);
  }, [mobileMenuIsOpen]);

  useEffect(() => {
    if (skipLinkRef.current && document.body.classList.contains('keyboard-nav'))
      skipLinkRef.current.focus();
  }, [location, location.href]);

  useEffect(() => {
    window.addEventListener('scroll', closeAnnouncementDrawer);

    return () => window.removeEventListener('scroll', closeAnnouncementDrawer);
  }, [closeAnnouncementDrawer]);

  useEffect(() => {
    const headerHeight = hideHeader ? '30px' : '50px';
    document.documentElement.style.setProperty('--header-height', headerHeight);
  });

  useEffect(() => {
    updateBannerVisibility(Boolean(showAnnouncement));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   *   Nav items
   */
  let navItems = data?.contentfulNavigationMenu?.links?.map((link) => (
    <NavItem
      key={link.id}
      id={link.id}
      to={link.url}
      label={link.title}
      description={link.description}
      icon={link.icon}
      columns={link.columns}
      featuredContent={link.featuredContent}
      mainNavigationReplacement={link.mainNavigationReplacement}
      navigationFooterLink={link.navigationFooterLink}
      accordion={windowSize.width < 901}
      forceClose={location.href}
      isSelected={link.id === selectedNavItem && windowSize.width < 901}
      onSelectedNavItem={() => setSelectedNavItem(link.id)}
      onClick={() => {
        if (!link.sublinks) setMobileMenuIsOpen(false);
      }}
      showAnnouncement={
        showAnnouncement &&
        !hideAnnouncementBar &&
        isLoaded &&
        windowSize.width > 900
      }
      sublinks={link?.sublinks}
    >
      {link?.sublinks?.map((sublink) => (
        <NavDropdownItem
          key={sublink.id}
          to={sublink.url}
          category={sublink.category}
          onClick={() => setMobileMenuIsOpen(false)}
          description={sublink.description}
          icon={sublink.icon}
        >
          {sublink.title}
        </NavDropdownItem>
      ))}
    </NavItem>
  ));

  if (windowSize.width <= 900) {
    navItems = navItems.concat([
      <NavItem key="support-mobile" id="support-mobile">
        <SmartLink
          to="https://support.newsela.com/contact"
          data-qa-selector="unauth_homepage_support"
          className={styles.customLinkMobile}
          aria-label="Support homepage"
        >
          Support
        </SmartLink>
      </NavItem>,
      <NavItem key="contact-us-mobile" id="contact-us-mobile">
        <SmartLink
          to="https://go.newsela.com/contact"
          data-qa-selector="unauth_homepage_contact_us"
          className={styles.customLinkMobile}
          aria-label="contact us homepage"
        >
          Contact us
        </SmartLink>
      </NavItem>,
    ]);
  }

  const announcementDrawerCards = [
    {
      title: settings.card1Title,
      text: settings.card1Text ? settings.card1Text.card1Text : null,
      buttonText: settings.card1ButtonText,
      buttonURL: settings.card1ButtonUrl,
    },
    {
      title: settings.card2Title,
      text: settings.card2Text ? settings.card2Text.card2Text : null,
      buttonText: settings.card2ButtonText,
      buttonURL: settings.card2ButtonUrl,
    },
    {
      title: settings.card3Title,
      text: settings.card3Text ? settings.card3Text.card3Text : null,
      buttonText: settings.card3ButtonText,
      buttonURL: settings.card3ButtonUrl,
    },
  ].filter((c) => c.title || c.text || (c.buttonText && c.buttonURL));

  const announcementDrawerCardEls = announcementDrawerCards.map((c) => {
    return (
      <div
        key={c.title}
        className={classnames(styles.announcement__card, {
          [styles.two_up]: announcementDrawerCards.length < 3,
        })}
      >
        {!!c.title && (
          <h3 className={styles.announcement__card_title}>{c.title}</h3>
        )}
        {!!c.text && (
          <div className={styles.announcement__card_text}>{c.text}</div>
        )}
        {!!c.buttonText && !!c.buttonURL && (
          <SmartLink
            tabIndex={showAnnouncementDrawer ? null : -1}
            to={c.buttonURL}
            className={styles.announcement__card_button}
          >
            {c.buttonText}
          </SmartLink>
        )}
      </div>
    );
  });

  const announcementBarEl = (
    <aside
      aria-label="Announcement"
      ref={announcementContainerRef}
      className={classnames(styles.announcement_container, {
        [styles.visible]: showAnnouncement && isLoaded,
        [styles.drawer_visible]:
          showAnnouncement && showAnnouncementDrawer && isLoaded,
        [styles[bannerData?.backgroundColor]]: bannerData?.backgroundColor,
      })}
      style={{
        height:
          showAnnouncementDrawer && showAnnouncement
            ? `${announcementContainerRef.current.scrollHeight}px`
            : '',
      }}
    >
      <div className={classnames(styles.announcement)}>
        <button
          aria-label="close"
          className={styles.announcement__close}
          onClick={() => closeAnnouncement()}
        >
          <XIcon />
        </button>
        {bannerData?.announcementText?.json && (
          <RichText content={bannerData.announcementText.json} />
        )}
        {!!announcementDrawerCards.length && (
          <>
            <button
              className={styles.announcement__toggle_drawer}
              onClick={toggleAnnouncementDrawer}
              aria-expanded="true"
            >
              <DownCaretIcon />
            </button>
            <div
              className={styles.announcement__click_area}
              onClick={toggleAnnouncementDrawer}
            ></div>
          </>
        )}
      </div>
      {!!announcementDrawerCards.length && (
        <div className={styles.announcement_drawer}>
          <div className={styles.announcement_drawer_inner}>
            {announcementDrawerCardEls}
          </div>
        </div>
      )}
    </aside>
  );

  return (
    <>
      <SmartLink
        ref={skipLinkRef}
        to="#main-content-anchor"
        className={styles.skip_link}
        aria-label="Skip to Content"
      >
        Skip to Content
      </SmartLink>

      <header
        className={classnames(styles.container, {
          [styles.announcement_visible]:
            showAnnouncement && !hideAnnouncementBar && isLoaded,
          [styles.announcement_drawer_visible]:
            showAnnouncement && showAnnouncementDrawer && isLoaded,
        })}
      >
        {settings?.banner?.show && !hideAnnouncementBar && announcementBarEl}
        {!hideHeader && (
          <div className={styles.inner}>
            <nav className={styles.menu}>
              {windowSize.width < 901 && (
                <button
                  aria-label="Hamburger Menu"
                  ref={hamburgerRef}
                  className={classnames(styles.hamburger, {
                    [styles.active]: mobileMenuIsOpen,
                  })}
                  onClick={toggleMobileMenu}
                >
                  <div className={styles.hamburger__patty} />
                </button>
              )}
              <Link
                aria-label="Newsela logo, link to homepage"
                to="/"
                className={styles.logo}
              >
                <Logo />
                <Logomark />
              </Link>
              {windowSize.width > 900 && navItems}
            </nav>
            <div className={styles.contact_container}>
              <SmartLink
                to="https://go.newsela.com/contact"
                data-qa-selector="unauth_homepage_contact_us"
                className={styles.contact_button}
                aria-label="contact us homepage"
              >
                Contact us
              </SmartLink>
            </div>
            <div
              className={styles.right_menu}
              onMouseEnter={onMouseEnterHandler}
              onMouseLeave={onMouseLeaveHandler}
            >
              <SmartLink
                to="https://support.newsela.com/contact"
                data-qa-selector="unauth_homepage_support"
                className={styles.support}
                aria-label="Support homepage"
              >
                Support
              </SmartLink>
              <a
                href="/join"
                data-qa-selector="unauth_homepage_sign_up"
                className={styles.sign_up}
                aria-label="Sign up homepage"
              >
                Sign up
              </a>
              <a
                href="/signin"
                data-qa-selector="unauth_homepage_log_in"
                className={styles.sign_in}
                aria-label="Log In homepage"
              >
                <span>Log in</span>
                <Arrow
                  title="icon arrow"
                  role="img"
                  aria-label="icon arrow"
                  className={styles.arrow}
                />
              </a>
              {((!mobileMenuIsOpen && windowSize.width <= 900) ||
                (windowSize.width > 550 && isHoveringRightMenu)) && (
                <ClassCodeEntry />
              )}
            </div>
          </div>
        )}
      </header>
      {!hideHeader && (
        <MobileNav
          containerRef={mobileMenuRef}
          shouldRender={windowSize.width < 901 && mobileMenuIsOpen}
          className={classnames({
            [styles.announcement_visible]:
              showAnnouncement && !hideAnnouncementBar && isLoaded,
            [styles.announcement_visible_no_banner]:
              hideAnnouncementBar ||
              !showAnnouncement ||
              !settings?.banner?.show,
          })}
        >
          {navItems}
        </MobileNav>
      )}
      <div id="main-content-anchor" aria-label="Main page content"></div>
      <SmartLink
        to="#main-content-anchor"
        className={classnames(styles.back_to_top, {
          [styles.visible]: showBackToTop,
        })}
      >
        Back to top
      </SmartLink>
    </>
  );
}

export const NavigationLinkFragment = graphql`
  fragment NavigationLinkFragment on Node {
    ... on ContentfulNavigationLink {
      id
      title
      description
      url
      category
      columns
      icon {
        id
        title
        file {
          url
        }
      }
    }
  }
`;
