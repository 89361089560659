import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';

// Is this running client-side?
const isClient = typeof window === 'object';

// Get window size
function getSize() {
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined
  };
}

export default function useWindowSize(delay = 0) {
  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    window.onload = () => setWindowSize(getSize());

    const handleResize = throttle(() => {
      setWindowSize(getSize());
    }, delay);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [delay]);

  return windowSize;
}
