import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';
import colorTheme from 'helpers/colorTheme';
import SmartLink from 'components/smartLink';
import RichText from 'components/richText';
import EmbeddedContent from 'components/embeddedContent';
import TextWithBr from 'components/textWithBr';
import styles from './billboard.module.scss';
import checkForMobile from 'is-mobile';

export default function Billboard({
  title,
  headLabel,
  subtitle,
  copy,
  textColor,
  buttonText,
  buttonUrl,
  backgroundColor,
  backgroundImage,
  mobileBackgroundImage,
  sectionId,
  embedCode,
  embedCodePrefix,
}) {
  const [containerRef, inView] = useInView({ triggerOnce: true });

  const isMobile = useMemo(() => checkForMobile(), []);
  const styleTag = colorTheme('background-color', backgroundColor, 'dark');
  const styleTagText = textColor ? colorTheme('color', textColor, 'light') : {color: '#fff'};
  if (isMobile && mobileBackgroundImage) {
    styleTag.backgroundImage = `url(${mobileBackgroundImage.file.url})`;
  } else if (backgroundImage) {
    styleTag.backgroundImage = `url(${backgroundImage.file.url})`;
  }

  const subtitleContent = subtitle || copy;
  return (
    <section
      id={sectionId}
      style={styleTag}
      className={classnames(
        styles.container,
        styles[backgroundColor],
        {
          [styles.revealed]: inView,
          [styles.with_embed]: embedCode,
          [styles.hasBackgroundImage]: backgroundImage || mobileBackgroundImage,
        }
      )}
    >
      <div className={styles.inner} ref={containerRef} style={styleTagText}>
        <div className={styles.text}>
          {headLabel && <h4 className={styles.label}>{headLabel}</h4>}
          {title && (
            <TextWithBr tag={'h2'} className={styles.title} content={title} />
          )}
          {subtitleContent && (
            <h2 className={styles.subtitle}>
              <RichText content={subtitleContent.json} />
            </h2>
          )}
          {buttonText && (
            <SmartLink to={buttonUrl} className={styles.button}>
              {buttonText}
            </SmartLink>
          )}
        </div>
        {embedCode && (
          <div className={styles.embed}>
            {embedCodePrefix && (
              <div className={styles.embed__prefix}>{embedCodePrefix}</div>
            )}
            <EmbeddedContent content={embedCode.embedCode} />
          </div>
        )}
      </div>
    </section>
  );
}
