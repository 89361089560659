import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import formatDate from 'helpers/formatDate';
import slugify from 'helpers/slugify';
import SmartLink from './smartLink';
import styles from './pressReleases.module.scss';

export default function PressReleases({ title, sectionId, isFirstElement }) {
  /*
   *   Query main nav items
   */
  const releasesData = useStaticQuery(graphql`
    query releasesQuery {
      allContentfulPressRelease(sort: { fields: date, order: DESC }) {
        nodes {
          id
          title
          date
        }
      }
    }
  `);

  const [containerRef, inView] = useInView({ triggerOnce: true });

  const InnerElementTag = isFirstElement ? 'h1' : 'h2';

  function ReleaseCard({ title, slug, date }) {
    const formattedDate = formatDate(date);

    return (
      <article className={styles.release}>
        <SmartLink
          to={`/press-releases/${
            slug ? slugify(slug) : slugify(title).toLowerCase()
          }`}
        >
          <h2 className={styles.release__title}>{title}</h2>
          <div className={styles.release__date}>{formattedDate}</div>
        </SmartLink>
      </article>
    );
  }

  const releaseEls = useMemo(
    () =>
      releasesData?.allContentfulPressRelease?.nodes?.map((a) => (
        <ReleaseCard key={a.id} slug={a.slug} title={a.title} date={a.date} />
      )),
    [releasesData.allContentfulPressRelease.nodes]
  );

  /*
   *   Return
   */
  return (
    <section
      id={sectionId}
      ref={containerRef}
      className={classnames(styles.container, { [styles.revealed]: inView })}
    >
      <div className={styles.inner}>
        <InnerElementTag className={styles.title}>{title}</InnerElementTag>
        <div className={styles.releases}>{releaseEls}</div>
      </div>
    </section>
  );
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const pressReleasesFragment = graphql`
  fragment PressReleasesFragment on ContentfulPressReleases {
    sectionId
    title
  }
`;
