import React from 'react';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';
import RichText from 'components/richText';
import styles from './customList.module.scss';

const types = {
  'custom-list': styles.customList,
  'custom-bullet-entire-background': styles.customBulletEntireBackground,
  'custom-bullet-each-background': styles.customBulletEachBackground,
};

const gridRows = {
  1: styles.noColumns,
  2: styles.grid2,
  3: styles.grid3,
};

export default function CustomList({ type, rows, list }) {
  const [containerRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <section
      ref={containerRef}
      className={classnames(styles.container, {
        [styles.revealed]: inView,
      })}
    >
      <RichText
        content={list}
        className={classnames(
          styles.customListContainer,
          types[type],
          gridRows[rows]
        )}
      />
    </section>
  );
}
