import React from 'react';
import { graphql } from 'gatsby';
import heroClassic from 'components/heroClassic';
import heroBackgroundSlideshow from 'components/heroBackgroundSlideshow';
import heroProminentText1 from 'components/heroProminentText1';
import heroProminentText2 from 'components/heroProminentText2';
import heroMixedSlideshow from 'components/heroMixedSlideshow';
import heroTextAndCards from 'components/heroTextAndCards';
import heroTypewriter from 'components/heroTypewriter';

export default function Hero(props) {
  let Component = heroClassic;

  switch (props.type) {
    case 'Background Slideshow':
      Component = heroBackgroundSlideshow;
      break;
    case 'Prominent Text 1':
      Component = heroProminentText1;
      break;
    case 'Prominent Text 2':
      Component = heroProminentText2;
      break;
    case 'Mixed Slideshow':
      Component = heroMixedSlideshow;
      break;
    case 'Text and Cards':
      Component = heroTextAndCards;
      break;
    case 'Typewriter':
      Component = heroTypewriter;
      break;
    default:
      Component = heroClassic;
  }

  /*
   *   Render
   */
  return <Component {...props} />;
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const heroFragment = graphql`
  fragment HeroFragment on Node {
    ... on ContentfulHero {
      type
      title
      subtitle {
        json
      }
      subSubTitle {
        json
      }
      label
      labelIcon {
        file {
          url
          contentType
        }
      }
      labelImage {
        file {
          url
          contentType
        }
      }
      buttonText
      buttonUrl
      secondButtonText
      secondButtonUrl
      graphic {
        file {
          url
          contentType
        }

        fluid(maxWidth: 575, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      videoPoster {
        file {
          url
        }
      }
      vidyardEmbed {
        vidyardEmbed
      }
      backgroundColor
      backgroundImages {
        file {
          url
        }
        fluid(maxWidth: 1400) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        fixed(width: 1400) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      slides {
        id
        title
        intro {
          json
        }
        label
        labelIcon {
          file {
            url
            contentType
          }
        }
        buttonText
        buttonUrl
        secondButtonText
        secondButtonUrl
        alignment
        backgroundColor
        graphic {
          file {
            url
            contentType
          }
          fluid(maxWidth: 740) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        vidyardEmbed {
          vidyardEmbed
        }
        videoPoster {
          file {
            url
          }
        }
        backgroundImage {
          file {
            url
          }
        }
        boxShadow
      }
      addDropShadow
    }
  }
`;
