import React from 'react';
import uuidv1 from 'uuid/v1';
import styles from './embeddedTable.module.scss';

export default function EmbeddedTable({ rows }) {
  const thEls = rows[0].map((cell) => <th key={uuidv1()}>{cell}</th>);

  const tdEls = rows.slice(0).map((row) => {
    const cellEls = row.map((cell) => <td key={uuidv1()}>{cell}</td>);
    return <tr key={uuidv1()}>{cellEls}</tr>;
  });

  return (
    <div className={styles.container}>
      <table>
        <thead>
          <tr>{thEls}</tr>
        </thead>
        <tbody>{tdEls}</tbody>
      </table>
    </div>
  );
}
