/*
 *   A block of text next to three links with icons
 */

import React from 'react';
import classnames from 'classnames';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import colorTheme from 'helpers/colorTheme';
import SmartLink from 'components/smartLink';
import LazyImage from 'components/lazyImage';
import RichText from 'components/richText';
import styles from './callout.module.scss';

export default function Callout({
  title,
  copy,
  buttonText,
  buttonUrl,
  className,
  links,
  backgroundColor,
  sectionId,
  horizontalOrientation,
}) {
  const [containerRef, inView] = useInView({ triggerOnce: true });

  const linkEls = links.map(({ id, title, icon, buttonText, buttonUrl }) =>
    buttonUrl ? (
      <SmartLink
        to={buttonUrl}
        className={classnames(styles.link, {
          [styles.horizontalLink]: horizontalOrientation,
        })}
        key={id}
      >
        {icon && (
          <LazyImage
            className={
              horizontalOrientation ? styles.horizontalIcon : styles.icon
            }
            src={icon.file.url}
          />
        )}
        {!horizontalOrientation && (
          <>
            <div className={styles.link__title}>{title}</div>
            <div className={styles.link__button}>{buttonText}</div>
          </>
        )}
      </SmartLink>
    ) : (
      <div className={styles.link} key={id}>
        {icon && <LazyImage className={styles.icon} src={icon.file.url} />}
        <div className={styles.link__title}>{title}</div>
        <div className={styles.link__button}>{buttonText}</div>
      </div>
    )
  );

  return (
    <section
      id={sectionId}
      ref={containerRef}
      style={colorTheme('background-color', backgroundColor)}
      className={classnames(styles.container, className, {
        [styles.revealed]: inView,
        [styles.horizontalContainer]: horizontalOrientation,
      })}
    >
      <div className={styles.inner}>
        <div className={styles.text}>
          {title && (
            <h2
              className={classnames(styles.title, {
                [styles.horizontalTitle]: horizontalOrientation,
              })}
            >
              {title}
            </h2>
          )}
          {copy && (
            <div className={styles.copy}>
              <RichText content={copy.json} />
            </div>
          )}
          {buttonText && (
            <SmartLink to={buttonUrl} className={styles.button}>
              {buttonText}
            </SmartLink>
          )}
        </div>
        <div
          className={classnames(styles.links, {
            [styles.horizontalLinks]: horizontalOrientation,
          })}
        >
          {linkEls}
        </div>
      </div>
    </section>
  );
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const calloutFragment = graphql`
  fragment CalloutFragment on Node {
    ... on ContentfulCallout {
      sectionId
      title
      copy {
        json
      }
      buttonText
      buttonUrl
      backgroundColor
      horizontalOrientation
      links {
        id
        title
        buttonText
        buttonUrl
        icon {
          id
          title
          file {
            url
          }
        }
      }
    }
  }
`;
