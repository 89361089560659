import React from 'react';
import LazyImage from 'components/lazyImage';
import RichText from 'components/richText';
import SmartLink from 'components/smartLink';
import styles from './advertisement.module.scss';

const Advertisement = ({
  image,
  title,
  description,
  buttonUrl,
  buttonText,
}) => {
  return (
    <div className={styles.advertisement}>
      {image && (
        <LazyImage
          height="100"
          className={styles.advertisement_image}
          src={image.file.url}
        />
      )}

      <div className={styles.advertisement_content}>
        {title && <h4 className={styles.advertisement_title}>{title}</h4>}

        {description && (
          <RichText
            className={styles.advertisement_json}
            content={description}
          />
        )}

        {buttonUrl && (
          <SmartLink
            className={styles.advertisement_link}
            to={buttonUrl}
            aria-label={buttonText}
          >
            {buttonText}
          </SmartLink>
        )}
      </div>
    </div>
  );
};

export default Advertisement;
