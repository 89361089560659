import React from 'react';
import classnames from 'classnames';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import colorTheme from 'helpers/colorTheme';
import RichText from 'components/richText';
import SmartLink from 'components/smartLink';
import styles from './cta.module.scss';

export default function Cta({
  title,
  headLabel,
  copy,
  backgroundColor,
  buttonText,
  buttonUrl,
  buttonText2,
  buttonUrl2,
  format,
  sectionId,
  buttonBackgroundColor,
  buttonColor,
  isFirstElement,
}) {
  const [containerRef, inView] = useInView({ triggerOnce: true });
  const InnerElementTag = isFirstElement ? 'h1' : 'h2';

  return (
    <section
      id={sectionId}
      style={colorTheme('background-color', backgroundColor)}
      className={classnames(
        styles.container,
        styles[format],
        styles[backgroundColor],
        {
          [styles.revealed]: inView,
          [styles.ctaContainer]: isFirstElement,
        }
      )}
    >
      <div className={styles.inner} ref={containerRef}>
        <div className={styles.text}>
          {title && (
            <InnerElementTag className={styles.cta_title}>
              {title}
            </InnerElementTag>
          )}
          {headLabel && <p className={styles.headLabel}>{headLabel}</p>}
          {copy && <RichText className={styles.copy} content={copy.json} />}
        </div>
        <div className={styles.buttons}>
          {buttonText && (
            <SmartLink
              className={styles.button}
              to={buttonUrl}
              style={{
                backgroundColor: buttonBackgroundColor,
                color: buttonColor,
              }}
            >
              {buttonText}
            </SmartLink>
          )}
          {buttonText2 && (
            <SmartLink
              className={styles.button}
              to={buttonUrl2}
              style={{
                backgroundColor: buttonBackgroundColor,
                color: buttonColor,
              }}
            >
              {buttonText2}
            </SmartLink>
          )}
        </div>
      </div>
    </section>
  );
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const ctaFragment = graphql`
  fragment CTAFragment on Node {
    ... on ContentfulCta {
      title
      headLabel
      backgroundImage {
        file {
          url
        }
        fluid(maxWidth: 1400) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        fixed(width: 1400) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      mobileBackgroundImage {
        file {
          url
        }
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        fixed(width: 800) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      copy {
        json
      }
      backgroundColor
      textColor
      buttonText
      buttonUrl
      buttonText2
      buttonUrl2
      format
      sectionId
      embedCode {
        embedCode
      }
      embedCodePrefix
    }
  }
`;
