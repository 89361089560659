import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import colorTheme from 'helpers/colorTheme';
import { useInView } from 'react-intersection-observer';
import SmartLink from 'components/smartLink';
import RichText from 'components/richText';
import TextWithBr from 'components/textWithBr';
import Roundabout from 'components/roundabout';
import Img from 'gatsby-image/withIEPolyfill';
import { ReactComponent as LeftCaretIcon } from 'images/icon-caret-left.svg';
import { ReactComponent as RightCaretIcon } from 'images/icon-caret-right.svg';
import styles from './heroTextAndCards.module.scss';

export default function HeroMixedSlideshow({
  sectionId,
  title,
  label,
  labelIcon,
  subtitle,
  buttonText,
  buttonUrl,
  secondButtonText,
  secondButtonUrl,
  slides,
  backgroundColor,
}) {
  const [containerRef, inView] = useInView({ triggerOnce: true });
  const [currentSlide, setCurrentSlide] = useState(0);
  const cardsContainerRef = useRef();

  function advance() {
    const next = currentSlide >= slides.length - 1 ? 0 : currentSlide + 1;
    setCurrentSlide(next);
  }

  function retreat() {
    const prev = currentSlide === 0 ? slides.length - 1 : currentSlide - 1;
    setCurrentSlide(prev);
  }

  let cardEls = null;
  if (slides && slides.length) {
    if (slides.length > 1) {
      cardEls = (
        <Roundabout
          currentSlide={currentSlide}
          onSlideChange={(index) => setCurrentSlide(index)}
          container={cardsContainerRef.current}
          wrapAround={true}
        >
          {slides.map((slide) => (
            <div className={styles.card} key={slide.id}>
              {slide.graphic && (
                <Img
                  className={styles.card__image}
                  fluid={slide?.graphic?.fluid}
                />
              )}
              <div className={styles.card__content}>
                <h2 className={styles.card__title}>{slide.title}</h2>

                <RichText
                  className={styles.card__intro}
                  content={slide.intro.json}
                />
                <SmartLink to={slide.buttonUrl} className={styles.card__cta}>
                  {slide.buttonText}
                </SmartLink>
              </div>
            </div>
          ))}
        </Roundabout>
      );
    } else {
      cardEls = (
        <div className={styles.card}>
          <Img
            className={styles.card__image}
            fluid={slides[0]?.graphic?.fluid}
          />
          <div className={styles.card__content}>
            <h2 className={styles.card__title}>{slides[0].title}</h2>
            <RichText
              className={styles.card__intro}
              content={slides[0].intro.json}
            />
          </div>
        </div>
      );
    }
  }

  /*
   *   Return
   */
  return (
    <section
      style={colorTheme('background-color', backgroundColor)}
      id={sectionId}
      ref={containerRef}
      className={classnames(styles.container, {
        [styles.loaded]: inView,
      })}
    >
      <div className={styles.inner}>
        <div className={styles.text}>
          {label && (
            <div className={styles.label}>
              {labelIcon && <img src={labelIcon.file.url} alt={label || ''} />}
              {label}
            </div>
          )}
          {title && (
            <TextWithBr className={styles.title} tag="h1" content={title} />
          )}
          {subtitle && (
            <h2 className={styles.subtitle}>
              <RichText content={subtitle.json} />
            </h2>
          )}
          {buttonText && (
            <SmartLink to={buttonUrl} className={styles.button}>
              {buttonText}
            </SmartLink>
          )}
          {secondButtonText && (
            <SmartLink to={secondButtonUrl} className={styles.button}>
              {secondButtonText}
            </SmartLink>
          )}
        </div>
        {cardEls ? (
          <div className={styles.cards} ref={cardsContainerRef}>
            {cardEls}
          </div>
        ) : null}
        <div className={styles.navigation}>
          <button
            className={styles.navigation__button}
            onClick={retreat}
            aria-label="Previous slide"
          >
            <LeftCaretIcon />
          </button>
          <button
            className={styles.navigation__button}
            onClick={advance}
            aria-label="Next slide"
          >
            <RightCaretIcon />
          </button>
        </div>
      </div>
    </section>
  );
}
