import React from 'react';
import Ticker from 'react-ticker';
import useVisibility from 'hooks/useVisibility';

export default function NewsTicker ({ children, className }) {
  const isVisible = useVisibility();

  /*
   *   Render
   */
  return isVisible ? (
    <div className={className} style={{ width: '100%', height: '100%' }}>
      <Ticker speed={3}>{({ index }) => children[index % children.length]}</Ticker>
    </div>
  ) : null;
}
