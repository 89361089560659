import React from 'react';
import MultipurposeContainer from './multipurposeContainer';
import Hero from './hero';
import LogoLine from './logoLine';
import Callout from './callout';
import TabbedGallery from './tabbedGallery';
import TabbedContent from './tabbedContent';
import QuoteCarousel from './quoteCarousel';
import Billboard from './billboard';
import ChecklistWithQuotes from './checklistWithQuotes';
import ExampleContent from './exampleContent';
import TextAndGraphic from './textAndGraphic';
import ContentSquares from './contentSquares';
import TestimonialCarousel from './testimonialCarousel';
import TeamMembers from './teamMembers';
import VideoSection from './videoSection';
import PressHighlight from './pressHighlight';
import PressArticles from './pressArticles';
import PressReleases from './pressReleases';
import Cta from './cta';
import EmbeddedWidget from './embeddedWidget';
import LongText from './longText';
import Pillars from './pillars';
import ComplexText from './complexText';
import SlideDeck from './slideDeck';
import Contact from './contact';
import Container from './container';
import TabsContainer from './tabsContainer';
import CodeBlock from './codeBlock';

const components = {
  MultipurposeContainer,
  Hero,
  LogoLine,
  Callout,
  TabbedGallery,
  TabbedContent,
  QuoteCarousel,
  Billboard,
  ChecklistWithQuotes,
  ExampleContent,
  TextAndGraphic,
  ContentSquares,
  TestimonialCarousel,
  TeamMembers,
  VideoSection,
  PressHighlight,
  PressArticles,
  PressReleases,
  Cta,
  EmbeddedWidget,
  LongText,
  Pillars,
  ComplexText,
  SlideDeck,
  Contact,
  Container,
  TabsContainer,
  CodeBlock,
};

export const generateBlocks = ({ blocks, location, page }) => {
  return blocks?.map((blockData, index) => {
    const componentName = blockData.__typename
      .replace('Contentful', '')
      .trim()
      .replace(/\s+/g, '');
    let BlockComponent = components[componentName];

    if (!BlockComponent) return null;

    /*
     *   Substitutions
     */
    if (componentName === 'Cta' && blockData.format === 'Billboard') {
      BlockComponent = components.Billboard;
    }
    if (
      componentName === 'TestimonialCarousel' &&
      blockData.layout === 'Layout 3'
    )
      BlockComponent = components.QuoteCarousel;

    if (
      componentName === 'MultipurposeContainer' &&
      page?.slug === '/contact'
    ) {
      BlockComponent = components.Contact;
    }

    return (
      <BlockComponent
        isFirstElement={index === 0}
        location={location || null}
        key={blockData.id}
        {...blockData}
      />
    );
  });
};

export default components;
