import React from 'react';
import classnames from 'classnames';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import colorTheme from 'helpers/colorTheme';
import SmartLink from 'components/smartLink';
import RichText from 'components/richText';
import TextWithBr from 'components/textWithBr';
import LazyImage from 'components/lazyImage';
import styles from './contentSquares.module.scss';

function ContentSquare({
  image,
  title,
  label,
  text,
  linkOne,
  boxShadow,
  isRounded,
  backgroundColor,
  buttonText,
  buttonUrl,
  buttonText2,
  buttonUrl2,
}) {
  const boxShadowClasses = {
    Small: styles['box__shadow__small'],
    Medium: styles['box__shadow__medium'],
    Large: styles['box__shadow__large'],
  };

  return (
    <div
      className={classnames(styles.square, {
        [boxShadowClasses[boxShadow]]: boxShadowClasses[boxShadow],
        [styles.isRounded]: isRounded,
        [styles['square__white']]: backgroundColor === 'White',
      })}
      alt=" "
    >
      {label && <div className={styles.square__label}>{label}</div>}
      {image?.file && (
        <div>
          <LazyImage
            height="100"
            className={styles.square__image}
            src={image.file.url}
          />
        </div>
      )}
      {title && (
        <div>
          <TextWithBr
            className={styles.square__title}
            tag="h3"
            content={title}
          />
        </div>
      )}
      {text && (
        <RichText
          className={styles.square__text}
          innerClassName={styles.square__inner_text}
          content={text.json}
        />
      )}
      {linkOne && (
        <SmartLink to={linkOne} className={styles.linkOne__text}>
          {linkOne}
        </SmartLink>
      )}

      {(buttonText || buttonText2) && (
        <div className={styles.square__links}>
          {buttonText && (
            <SmartLink to={buttonUrl} className={styles.button__text}>
              {buttonText}
            </SmartLink>
          )}
          {buttonText2 && (
            <SmartLink to={buttonUrl2} className={styles.button__text}>
              {buttonText2}
            </SmartLink>
          )}
        </div>
      )}
    </div>
  );
}

export default function ContentSquares({
  title,
  showTitle,
  squaresSubtitle,
  squares,
  notes,
  cta,
  buttonText,
  buttonUrl,
  borderRadius,
  boxShadow,
  backgroundColor,
  squareSize,
  sectionId,
}) {
  const [containerRef, inView] = useInView({ triggerOnce: true });

  const squareEls = squares.map((square) => (
    <ContentSquare
      key={square.id}
      boxShadow={boxShadow}
      borderRadius={borderRadius}
      {...square}
    />
  ));

  backgroundColor = backgroundColor || 'White';
  return (
    <section
      id={sectionId}
      style={colorTheme('background-color', backgroundColor)}
      className={classnames(
        styles.container,
        styles[squareSize],
        styles[backgroundColor],
        {
          [styles.revealed]: inView,
        }
      )}
    >
      <div className={styles.inner} ref={containerRef}>
        <div className={styles.text}>
          {title && showTitle && (
            <TextWithBr tag="h2" className={styles.title} content={title} />
          )}
          {squaresSubtitle && (
            <TextWithBr
              className={styles.subtitle}
              tag="h3"
              content={squaresSubtitle}
            />
          )}
          {squares && <div className={styles.squares}>{squareEls}</div>}
          {notes && <RichText className={styles.notes} content={notes.json} />}
          {cta && <div className={styles.cta}>{cta}</div>}
          {buttonText && (
            <SmartLink to={buttonUrl} className={styles.button}>
              {buttonText}
            </SmartLink>
          )}
        </div>
      </div>
    </section>
  );
}
/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const contentSquares = graphql`
  fragment ContentSquaresFragment on Node {
    ... on ContentfulContentSquares {
      id
      sectionId
      title
      showTitle
      squaresSubtitle: subtitle
      backgroundColor
      squareSize
      boxShadow
      borderRadius
      squares {
        id
        title
        label
        backgroundColor
        text {
          json
        }
        image {
          title
          file {
            url
          }
        }
        buttonText
        buttonUrl
        buttonText2
        buttonUrl2
      }
      notes {
        json
      }
      cta
      buttonText
      buttonUrl
    }
  }
`;
