const colorMapDark = {
  Black: '#000000',
  Grey: '#1273f8',
  Blue: '#1273f8',
  Red: '#ff3005',
  Pink: '#DA2C81',
  Purple: '#9e21ff',
  Orange: '#ff6230',
  Yellow: '#ffa829',
};

const colorMapLight = {
  White: '#ffffff',
  Grey: '#f6f6f6',
  Blue: '#ecf3ff',
  'Blue 10%': '#ecf3ff',
  'Blue 25%': '#c2dbfe',
  'Blue 50%': '#85b7fd',
  Red: '#FFE2E2',
  'Red 10%': '#FFE2E2',
  'Red 25%': '#ffc3b0',
  Pink: '#fff1f6',
  'Pink 10%': '#fff1f6',
  'Pink 25%': '#FFDBE8',
  'Pink 50%': '#FFDCF5',
  Purple: '#f6ecff',
  'Purple 10%': '#f6ecff',
  'Purple 25%': '#E8CFFF',
  'Purple 50%': '#D09EFF',
  Orange: '#FFEBDF',
  'Orange 10%': '#FFEBDF',
  'Orange 25%': '#FFD6CA',
  'Orange 50%': '#FFAD94',
  Yellow: '#FFF8ED',
  'Yellow 10%': '#FFF8ED',
  'Yellow 25%': '#FFEDD1',
  'Yellow 50%': '#FFDBA3',
};

function getBaseColor(color) {
  if (!color) return null;
  return Object.keys(colorMapDark).reduce(
    (acc, curr) => (color.includes(curr) ? colorMapDark[curr] : acc),
    ''
  );
}

export default function colorTheme(property, color, realm = 'light') {
  switch (property) {
    case 'background-color':
      return realm === 'light'
        ? { backgroundColor: colorMapLight[color] }
        : { backgroundColor: getBaseColor(color) };
    case 'border-color':
      return { borderColor: getBaseColor(color) };
    case 'color': {
      const colorMap = { ...colorMapDark, ...colorMapLight };
      return { color: colorMap[color] || color };
    }
    default:
      return null;
  }
}
