import React from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';
import useWindowSize from 'hooks/useWindowSize';
import NewsTicker from 'components/newsTicker';
import SmartLink from 'components/smartLink';
import TextWithBr from 'components/textWithBr';
import styles from './logoLine.module.scss';

export default function LogoLine({
  title,
  logos,
  ctaText,
  ctaUrl,
  backgroundColor,
  infiniteScroll,
  sectionId,
}) {
  const [logosRef, logosInView] = useInView({ triggerOnce: true });
  const windowSize = useWindowSize(500);

  const bgColor = backgroundColor ? backgroundColor.replace(/\s+/g, '-') : null;
  const maxImageHeight = windowSize.width > 1080 ? 70 : 65;
  const maxImageWidth = windowSize.width > 1080 ? 200 : 150;

  const logoEls = logos
    ? logos.map((logo) => {
        const processedImage = `${logo.file.url}?h=140`;
        return (
          <picture key={logo.id} className={styles.logo}>
            <img
              src={processedImage}
              alt={logo.title}
              height={logo.file.details.image.height / 2}
              width={
                infiniteScroll || windowSize.width < 1080
                  ? logo.file.details.image.width / 2
                  : '100%'
              }
              style={{
                maxHeight: `${Math.min(
                  logo.file.details.image.height / 2,
                  maxImageHeight
                )}px`,
                maxWidth: `${Math.min(
                  logo.file.details.image.width / 2,
                  maxImageWidth
                )}px`,
              }}
              loading="lazy"
            />
          </picture>
        );
      })
    : null;

  const logosContainer =
    infiniteScroll || windowSize.width < 1080 ? (
      <NewsTicker>{logoEls}</NewsTicker>
    ) : (
      <div className={styles.no_scroll_container}>{logoEls}</div>
    );

  return (
    <section
      id={sectionId}
      className={classnames(styles.container, styles[bgColor], {
        [styles.revealed]: logosInView,
      })}
    >
      <div className={styles.inner}>
        <TextWithBr className={styles.title} tag="h3" content={title} />
      </div>
      <div className={styles.logos} ref={logosRef}>
        {logosInView && logosContainer}
      </div>
      {ctaText && ctaUrl && (
        <SmartLink to={ctaUrl} className={classnames(styles.cta)}>
          {ctaText}
        </SmartLink>
      )}
    </section>
  );
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const logoLinFragment = graphql`
  fragment LogoLineFragment on Node {
    ... on ContentfulLogoLine {
      title
      sectionId
      logos {
        id
        title
        file {
          contentType
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
      ctaUrl
      ctaText
      backgroundColor
      infiniteScroll
    }
  }
`;
