import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
} from 'react';
import classnames from 'classnames';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import colorTheme from 'helpers/colorTheme';
import forceLayout from 'helpers/forceLayout';
import SmartLink from 'components/smartLink';
import RichText from 'components/richText';
import { Choosy, OptionGroup, Option } from 'components/choosy';
import styles from './tabbedContent.module.scss';

export default function TabbedContent({ title, tabs, autoPlay, sectionId }) {
  const [isPaused, setIsPaused] = useState(false);
  const [containerRef, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });
  const [panelRef, panelInView] = useInView({ threshold: 0.3 });
  const intervalRef = useRef(null);
  const [panelIsVisible, setPanelIsVisible] = useState(false);
  const [currentPanelIndex, setCurrentPanelIndex] = useState(0);

  /*
   *   Go to panel
   */
  const goToPanel = useCallback(function (panelIndex) {
    setPanelIsVisible(false);
    setCurrentPanelIndex(panelIndex);
  }, []);

  /*
   *   Advance to next image
   */
  const advance = useCallback(
    function () {
      if (!panelInView) return;
      setPanelIsVisible(false);

      // At the end -> start from 0
      if (currentPanelIndex >= tabs.length - 1) {
        goToPanel(0);
      } else {
        goToPanel(currentPanelIndex + 1);
      }
    },
    [currentPanelIndex, goToPanel, panelInView, tabs]
  );

  /*
   *   Use Effect
   */

  useEffect(() => {
    if (!autoPlay) return;
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (!isPaused) advance();
    }, 4000);
    return () => clearInterval(intervalRef.current);
  }, [tabs, advance, isPaused, autoPlay]);

  /*
   *   Use layout effect
   */
  useLayoutEffect(() => {
    if (panelIsVisible) return;
    forceLayout();
    const raf = requestAnimationFrame(() => setPanelIsVisible(true));

    return () => cancelAnimationFrame(raf);
  }, [panelIsVisible]);

  /*
   *   Tab els
   */
  const tabEls = tabs.map((tab, i) => {
    const icons = tab.icon;
    const isCurrent = currentPanelIndex === i;
    return (
      <button
        key={tab.id}
        onClick={() => {
          if (currentPanelIndex !== i) {
            goToPanel(i);
          }
        }}
        style={
          isCurrent
            ? colorTheme('background-color', tab.colorTheme, 'dark')
            : {}
        }
        className={classnames(styles.tab, {
          [styles.current]: isCurrent,
        })}
      >
        {icons && (
          <div className={styles.icon}>
            <div
              className={styles.icon_grey}
              style={{
                backgroundImage: `url(${icons[0].file.url})`,
              }}
            />
            {icons[1] && (
              <div
                className={styles.icon_white}
                style={{
                  backgroundImage: `url(${icons[1].file.url})`,
                }}
              />
            )}
          </div>
        )}
        <div className={styles.tab__title}>{tab.title}</div>
      </button>
    );
  });

  const choosyTabEls = tabs.map((tab, i) => {
    const icons = tab.icon;
    const isCurrent = currentPanelIndex === i;
    return (
      <Option
        key={tab.id}
        onClick={() => {
          if (currentPanelIndex !== i) {
            goToPanel(i);
          }
        }}
        style={
          isCurrent
            ? colorTheme('background-color', tab.colorTheme, 'dark')
            : {}
        }
        className={classnames(styles.choosy_tab, {
          [styles.current]: isCurrent,
        })}
      >
        {icons && (
          <div className={styles.icon}>
            <div
              className={styles.icon_grey}
              style={{
                backgroundImage: `url(${icons[0].file.url})`,
              }}
            />
            {icons[1] && (
              <div
                className={styles.icon_white}
                style={{
                  backgroundImage: `url(${icons[1].file.url})`,
                }}
              />
            )}
          </div>
        )}
        <div className={styles.choosy_tab__title}>{tab.title}</div>
      </Option>
    );
  });

  const panelData = tabs[currentPanelIndex];
  const imgSrc = panelData.image ? panelData.image.file.url : null;

  /*
   *   Return
   */
  return (
    <section
      aria-label="Carousel"
      id={sectionId}
      ref={containerRef}
      className={classnames(styles.container, { [styles.revealed]: inView })}
    >
      <div
        className={styles.inner}
        onMouseOver={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        <div className={styles.tabs}>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.tabs__tabs}>{tabEls}</div>
          <Choosy
            className={styles.choosy}
            label={tabs[currentPanelIndex].title}
          >
            <OptionGroup>{choosyTabEls}</OptionGroup>
          </Choosy>
        </div>
        <div
          ref={panelRef}
          style={colorTheme('border-color', panelData.colorTheme, 'dark')}
          className={classnames(styles.panel, styles[panelData.colorTheme], {
            [styles.visible]: panelIsVisible,
          })}
        >
          <div
            className={styles.panel__image}
            style={{ backgroundImage: `url(${imgSrc}?w=1450)` }}
          />
          <div className={styles.panel__text}>
            {panelData.textTitle && (
              <h2 className={styles.panel__title}>{panelData.textTitle}</h2>
            )}
            {panelData.text && (
              <RichText
                className={styles.panel__description}
                content={panelData.text.json}
              />
            )}
            {panelData.buttonText && (
              <SmartLink
                className={styles.panel__button}
                to={panelData.buttonUrl}
              >
                {panelData.buttonText}
              </SmartLink>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const tabbedContentFragment = graphql`
  fragment TabbedContentFragment on Node {
    ... on ContentfulTabbedContent {
      title
      sectionId
      tabs {
        id
        title
        colorTheme
        icon {
          file {
            contentType
            url
          }
        }
        image {
          file {
            url
          }
        }
        textTitle
        text {
          json
        }
        buttonText
        buttonUrl
      }
    }
  }
`;
