import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';
import get from 'lodash/get';
import Tab, { TabProps } from './tab';
import styles from './tabsContainer.module.scss';
import { useBanner } from '../contexts/bannerContext';

interface TabsContainerProps {
  title: string;
  tabs: [TabProps];
  location: any;
}

export default function TabsContainer(props: TabsContainerProps) {
  const [selectedTab, setSelectedTab] = useState(0);
  const targetRef = useRef(null);
  const isBannerVisible = get(useBanner(), 'isBannerVisible', false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabIndex = params?.get('tab');
    if (
      tabIndex &&
      !isNaN(parseInt(tabIndex)) &&
      parseInt(tabIndex) <= props.tabs.length - 1
    ) {
      setSelectedTab(parseInt(tabIndex));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabClick = (index) => {
    setSelectedTab(index);
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('tab', index);

    const path = location.href.split('?')[0];
    const newURL = `${path}?${urlParams}`;
    history.pushState({}, '', newURL);
  };

  return (
    <div>
      <div className={styles.linkRedirect} ref={targetRef} />
      <section>
        <div
          className={classnames(styles.tabs, {
            [styles.isBannerVisible]: isBannerVisible,
          })}
        >
          {props.tabs.map((tab, index) => (
            <button
              key={`tab-container-${index}`}
              className={classnames(styles.tabLinks, {
                [styles.active]: index === selectedTab,
              })}
              onClick={() => handleTabClick(index)}
            >
              {tab.tabName}
            </button>
          ))}
        </div>

        <div>
          {props.tabs.map((tab, index) => (
            <>{index === selectedTab && <Tab tab={tab} />}</>
          ))}
        </div>
      </section>
    </div>
  );
}

export const TabsContainerFragment = graphql`
  fragment TabsContainerFragment on Node {
    ... on ContentfulTabsContainer {
      title
      tabs {
        ... on ContentfulTab {
          ...tabFragment
        }
      }
    }
  }
`;
