import React, { useState, useEffect } from 'react';
import uuidv1 from 'uuid/v1';
import classnames from 'classnames';
import useWindowSize from '../hooks/useWindowSize';
import SmartLink from './smartLink';
import IconCheckBlue from '../images/icon-blue-check.svg';
import styles from './featureTable.module.scss';

export interface FeatureTableProps {
  id?: string;
  title: string;
  category?: string;
  description?: {
    json?: any;
  };
  column1LinkText?: string;
  column1LinkUrl?: string;
  column1Title?: string;
  column1TitleLink?: string;
  column1Icon?: {
    file?: {
      url?: string;
    };
  };
  column2LinkText?: string;
  column2LinkUrl?: string;
  column2Title?: string;
  column2TitleLink?: string;
  column2Icon?: {
    file?: {
      url?: string;
    };
  };
  column3LinkText?: string;
  column3LinkUrl?: string;
  column3Title?: string;
  column3TitleLink?: string;
  column3Icon?: {
    file?: {
      url?: string;
    };
  };
  column4LinkText?: string;
  column4LinkUrl?: string;
  column4Title?: string;
  column4TitleLink?: string;
  column4Icon?: {
    file?: {
      url?: string;
    };
  };
  highlightedColumn?: number;
  spreadsheet?: {
    file?: {
      url?: string;
    };
  };
  rows?: any;
}

/*
 *   This is a slightly brittle solution
 *   Long term, the CSV upload for feature tables should be replaced with a custom Contentful object editor
 */
function parsePseudoMarkup(string) {
  let lists = string.match(/LIST[\s\S]*ENDLIST/g);
  if (lists) {
    lists = lists.map((l) =>
      l
        .replace(/(\r\n|\n|\r)/gm, '')
        .replace(/LIST/, '<ul>')
        .replace(/ENDLIST/, '</ul>')
        .replace(/-/gm, '<li>')
    );
  }

  return string
    .replace(/\*(.*?)\*/g, '<div>$1</div>')
    .replace(/LIST[\s\S]*ENDLIST/g, lists);
}

/*
 *   Row
 */

function Row({
  title,
  column1,
  column2,
  column3,
  column4,
  numColumns,
  highlightedColumn,
}) {
  const windowSize = useWindowSize();
  function colContents(col) {
    if (col.toLowerCase() === 'x') {
      return <img src={IconCheckBlue} alt="Icon check" />;
    } else if (col) {
      return windowSize.width > 800 ? col : '*';
    } else {
      return null;
    }
  }

  const formattedTitle = parsePseudoMarkup(title);

  return (
    <div className={classnames(styles.row, styles[`cols_${numColumns}`])}>
      <div
        className={styles.row__title}
        dangerouslySetInnerHTML={{ __html: formattedTitle }}
      />
      <div
        className={classnames(styles.row__column, {
          [styles.highlighted]: highlightedColumn === 1,
        })}
      >
        {colContents(column1)}
      </div>
      {numColumns > 1 && (
        <div
          className={classnames(styles.row__column, {
            [styles.highlighted]: highlightedColumn === 2,
          })}
        >
          {colContents(column2)}
        </div>
      )}
      {numColumns > 2 && (
        <div
          className={classnames(styles.row__column, {
            [styles.highlighted]: highlightedColumn === 3,
          })}
        >
          {colContents(column3)}
        </div>
      )}
      {numColumns > 3 && (
        <div
          className={classnames(styles.row__column, {
            [styles.highlighted]: highlightedColumn === 4,
          })}
        >
          {colContents(column4)}
        </div>
      )}
    </div>
  );
}

/*
 *   Row Group
 */
function RowGroup({ title, rows, numColumns, highlightedColumn }) {
  const rowEls = rows
    ? rows.map((row) => (
        <Row
          key={uuidv1()}
          title={row.Feature}
          column1={row['Column 1']}
          column2={row['Column 2']}
          column3={row['Column 3']}
          column4={row['Column 4']}
          numColumns={numColumns}
          highlightedColumn={highlightedColumn}
        />
      ))
    : null;

  return (
    <div className={styles.row_group}>
      <h3 className={styles.row_group__title}>{title}</h3>
      <div className={styles.row_group__rows}>{rowEls}</div>
    </div>
  );
}

/*
 *   Feature Table
 */
export default function FeatureTable({ data }: { data: FeatureTableProps }) {
  const [isVisible, setIsVisible] = useState(false);

  /*
   *   Use Effect
   */
  useEffect(() => {
    setIsVisible(true);
  }, []);

  /*
   *   Columns
   */
  const columns = [];
  if (data?.column1Title) {
    columns.push({
      title: data.column1Title,
      titleLink: data.column1TitleLink ? data.column1TitleLink : null,
      linkText: data.column1LinkText,
      linkUrl: data.column1LinkUrl,
      icon: data.column1Icon,
    });
  }

  if (data?.column2Title) {
    columns.push({
      title: data.column2Title,
      titleLink: data.column2TitleLink ? data.column2TitleLink : null,
      linkText: data.column2LinkText,
      linkUrl: data.column2LinkUrl,
      icon: data.column2Icon,
    });
  }

  if (data?.column3Title) {
    columns.push({
      title: data.column3Title,
      titleLink: data.column3TitleLink ? data.column3TitleLink : null,
      linkText: data.column3LinkText,
      linkUrl: data.column3LinkUrl,
      icon: data.column3Icon,
    });
  }

  if (data?.column4Title) {
    columns.push({
      title: data.column4Title,
      titleLink: data.column4TitleLink ? data.column4TitleLink : null,
      linkText: data.column4LinkText,
      linkUrl: data.column4LinkUrl,
      icon: data.column4Icon,
    });
  }

  const columnEls = columns.map((column, i) => (
    <div
      className={classnames(styles.column, {
        [styles.highlighted]: data.highlightedColumn === i + 1,
      })}
      key={uuidv1()}
    >
      {column.icon && (
        <img
          className={styles.column__icon}
          src={column.icon.file.url}
          alt=""
        />
      )}
      {column.titleLink ? (
        <SmartLink
          className={styles.column__title}
          to={`/about/${column.titleLink.replace(/^\/+/, '')}`}
        >
          {column.title}
        </SmartLink>
      ) : (
        <div className={styles.column__title}>{column.title}</div>
      )}
      {column.linkText && (
        <SmartLink className={styles.column__link} to={column.linkUrl}>
          {column.linkText}
        </SmartLink>
      )}
    </div>
  ));

  const footerEls = columns.map((column, i) =>
    column.linkText ? (
      <div
        className={classnames(styles.footer__column, {
          [styles.highlighted]: data.highlightedColumn === i + 1,
        })}
        key={uuidv1()}
      >
        <SmartLink className={styles.column__link} to={column.linkUrl}>
          {column.linkText}
        </SmartLink>
      </div>
    ) : null
  );

  /*
   *   Row Groups
   */
  const rowGroupNames = data.rows
    ? data.rows.reduce((acc, curr) => acc.add(curr['Row Group']), new Set())
    : null;
  const rowGroups = rowGroupNames
    ? Array.from(rowGroupNames).map((rgn) => ({
        title: rgn,
        rows: data.rows.filter((r) => r['Row Group'] === rgn),
      }))
    : null;
  const rowGroupEls = rowGroups
    ? rowGroups.map((rg) => (
        <RowGroup
          key={uuidv1()}
          title={rg.title}
          rows={rg.rows}
          numColumns={columns.length}
          highlightedColumn={data.highlightedColumn}
        />
      ))
    : null;

  /*
   *   Return
   */
  return (
    <section
      className={classnames(
        styles.container,
        styles[`cols_${columns.length}`],
        {
          [styles.visible]: rowGroupEls && isVisible,
        }
      )}
    >
      <div className={styles.inner}>
        <div className={styles.table}>
          <div className={styles.columns}>{columnEls}</div>
          <div className={styles.rows}>{rowGroupEls}</div>
          <div className={styles.footer}>{footerEls}</div>
        </div>
      </div>
    </section>
  );
}
