import React, { useState } from 'react';
import classnames from 'classnames';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import removeBr from 'helpers/removeBr';
import colorTheme from 'helpers/colorTheme';
import ExampleContentCard from './exampleContentCard';
import SmartLink from 'components/smartLink';
import TextWithBr from 'components/textWithBr';
import { Choosy, Option } from 'components/choosy';
import styles from './exampleContent.module.scss';

export default function ExampleContent({
  title,
  exampleContentSubtitle,
  buttonText,
  buttonUrl,
  exampleContentCards,
  backgroundColor,
  sectionId,
  isFirstElement,
}) {
  const [containerRef, inView] = useInView({ triggerOnce: true });
  const cats = {};
  if (exampleContentCards) {
    exampleContentCards.forEach((c) => {
      if (c.category) return (cats[c.category.id] = c.category);
    });
  }
  const catsArray = Object.values(cats) || [];
  const [currentCat, setCurrentCat] = useState(
    catsArray.length ? catsArray[0].title : null
  );

  const catEls = catsArray.length
    ? catsArray.map((cat) => {
        const catName = cat.title.replace(/\s+/g, '');

        return (
          <button
            className={classnames(styles.cat, styles[catName], {
              [styles.is_current]: cat.title === currentCat,
            })}
            key={cat.id}
            onClick={() => setCurrentCat(cat.title)}
          >
            {cat.icon && <img src={cat.icon.file.url} alt={''} />}
            {cat.title && removeBr(cat.title).replace('Newsela ', '')}
          </button>
        );
      })
    : null;

  const catChoosyEls = catsArray.length
    ? catsArray.map((cat) => {
        const catName = cat.title.replace(/\s+/g, '');

        return (
          <Option
            className={classnames(styles.choosy_cat, styles[catName], {
              [styles.is_current]: cat.title === currentCat,
            })}
            key={cat.id}
            onClick={() => setCurrentCat(cat.title)}
          >
            {cat.icon && <img src={cat.icon.file.url} alt={cat.title} />}
            {cat.title && cat.title.replace('Newsela ', '')}
          </Option>
        );
      })
    : null;

  const exampleCards = exampleContentCards
    ? exampleContentCards
        .filter((c) => {
          return catsArray.length ? c.category.title === currentCat : true;
        })
        .map((c, i) => {
          return i < 3 ? (
            <ExampleContentCard
              className={styles.card}
              key={c.id}
              title={c.title}
              label={c.label}
              image={c.image}
              icons={c.icons}
              url={c.link}
              alt={''}
            />
          ) : null;
        })
    : [];

  return (
    <section
      id={sectionId}
      style={colorTheme('background-color', backgroundColor)}
      className={classnames(styles.container, {
        [styles.revealed]: inView,
      })}
    >
      <div className={styles.inner} ref={containerRef}>
        {title && (
          <TextWithBr
            tag={isFirstElement ? 'h1' : 'h2'}
            className={styles.title}
            content={title}
          />
        )}
        {exampleContentSubtitle && (
          <div className={styles.subtitle}>{exampleContentSubtitle}</div>
        )}
        {catsArray.length > 1 && <div className={styles.cats}>{catEls}</div>}
        {catsArray.length > 1 && (
          <Choosy className={styles.cats_choosy} label={currentCat}>
            {catChoosyEls}
          </Choosy>
        )}
        <div
          className={classnames(styles.cards, {
            [styles.two_cards]: exampleCards.length === 2,
          })}
        >
          {exampleCards}
        </div>
        {buttonText && (
          <SmartLink to={buttonUrl} className={styles.button}>
            {buttonText}
          </SmartLink>
        )}
      </div>
    </section>
  );
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const exampleContentFragment = graphql`
  fragment ExampleContentFragment on Node {
    ... on ContentfulExampleContent {
      sectionId
      title
      exampleContentSubtitle: subtitle
      buttonText
      buttonUrl
      backgroundColor
      exampleContentCards {
        id
        label
        title
        link
        icons {
          id
          title
          file {
            url
          }
        }
        category {
          id
          title
          icon {
            file {
              url
            }
          }
        }
        image {
          fluid(maxWidth: 700) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
      }
    }
  }
`;
