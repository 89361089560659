/*
 *   Forces sync/layout
 *   Useful when we need to guarantee that an element
 *   has been rendered before running css animations.
 *   Otherwise the element may render on the page
 *   with the end-state applied = no animation
 */
export default function forceLayout () {
  return document.body.offsetTop;
}
