import React from 'react';
import SmartLink from 'components/smartLink';
import styles from './contact.module.scss';

const Contact = ({ contentBlocks }) => (
  <section className={styles.container}>
    <div className={styles.inner}>
      <h1 className={styles.title}>Contact Newsela</h1>
      <h2 className={styles.subtitle}>We’re here to help.</h2>

      <div className={styles.blocks}>
        {contentBlocks.map((block) => (
          <div className={styles.block}>
            <h3 className={styles.block__title}>{block.title}</h3>
            <SmartLink to={block.buttonUrl} className={styles.block__link}>
              {block.buttonText}
            </SmartLink>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Contact;
