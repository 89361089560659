import React, { createContext, useState, useContext } from 'react';

const BannerContext = createContext();

export const BannerProvider = ({ children }) => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  const updateBannerVisibility = (isVisible) => {
    setIsBannerVisible(isVisible);
  };

  return (
    <BannerContext.Provider value={{ isBannerVisible, updateBannerVisibility }}>
      {children}
    </BannerContext.Provider>
  );
};

export const useBanner = () => {
  return useContext(BannerContext);
};
