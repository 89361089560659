import { useState, useEffect } from 'react';

// Is this running client-side?
const isClient = typeof window === 'object';
let hidden, visibilityChange;

if (isClient && typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (isClient && typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (isClient && typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

export default function useVisibility() {
  const [isVisible, setIsVisible] = useState(isClient ? !document[hidden] : null);

  function handleVisibilityChange() {
    if (isClient) {
      if (document[hidden]) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }
  }

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    document.addEventListener(visibilityChange, handleVisibilityChange, false);
    return () => document.removeEventListener(visibilityChange, handleVisibilityChange);
  }, []);

  return isVisible;
}
