export default function formatDate (date, options) {
  const opts = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
    ...options
  };
  return new Date(date).toLocaleDateString('en-US', opts);
}
