import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import forceLayout from 'helpers/forceLayout';
import SmartLink from 'components/smartLink';
import Img from 'gatsby-image/withIEPolyfill';
import RichText from 'components/richText';
import TextWithBr from 'components/textWithBr';
import styles from './heroProminentText2.module.scss';

export default function HeroProminentText2({
  title,
  subtitle,
  buttonText,
  buttonUrl,
  backgroundImages,
}) {
  const [isLoaded, setIsLoaded] = useState(false);

  /*
   *   Use Effect
   */
  useEffect(() => {
    forceLayout();
    requestAnimationFrame(() => {
      setIsLoaded(true);
    });
  }, [setIsLoaded]);



  /*
   *   Render
   */
  return (
    <section
      className={classnames(styles.container, {
        [styles.loaded]: isLoaded,
        [styles.has_background]: backgroundImages
      })}
    >
      {backgroundImages && <div className={styles.background_image_container}><Img style={{height:'100%', width: '100%'}} className={styles.background_image} fluid={backgroundImages[0].fluid} /></div>}
      <div className={styles.inner}>
        <div className={styles.text}>
          {title && <TextWithBr className={styles.title} tag='h1' content={title} />}
          {subtitle && (
            <h2 className={styles.subtitle}>
              <RichText content={subtitle.json} />
            </h2>
          )}
          {buttonText && (
            <SmartLink to={buttonUrl} className={classnames(styles.button)}>
              {buttonText}
            </SmartLink>
          )}  
        </div>

      </div>
    </section>
  );
}
