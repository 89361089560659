import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import useVidyard from 'hooks/useVidyard';
import forceLayout from 'helpers/forceLayout';
import removeSmartQuotes from 'helpers/removeSmartQuotes';
import SmartLink from 'components/smartLink';
import Media from './media';
import colorTheme from 'helpers/colorTheme';
import RichText from 'components/richText';
import TextWithBr from 'components/textWithBr';
import styles from './heroClassic.module.scss';

export default function HeroClassic({
  title,
  label,
  labelIcon,
  labelImage,
  subtitle,
  subSubTitle,
  buttonText,
  buttonUrl,
  secondButtonText,
  secondButtonUrl,
  graphic,
  vidyardEmbed,
  backgroundColor,
  addDropShadow,
  videoPoster,
}) {
  useVidyard(vidyardEmbed);
  const [isLoaded, setIsLoaded] = useState(false);

  /*
   *   Use Effect
   */
  useEffect(() => {
    forceLayout();
    requestAnimationFrame(() => {
      setIsLoaded(true);
    });
  }, [setIsLoaded]);

  /*
   *   Render
   */
  return (
    <section
      style={colorTheme('background-color', backgroundColor)}
      className={classnames(styles.container, {
        [styles.loaded]: isLoaded,
      })}
    >
      <div className={styles.inner}>
        <div
          className={classnames(styles.text, {
            [styles.centerText]:
              !label && !labelImage?.file?.url && !subSubTitle && !buttonText,
          })}
        >
          {label && (
            <div className={styles.label}>
              {labelIcon && (
                <img src={labelIcon.file.url} alt={label} loading="lazy" />
              )}
              {label}
            </div>
          )}
          {labelImage?.file?.url && (
            <div className={styles.labelImage}>
              <img src={labelImage.file.url} alt="Label image" loading="lazy" />
            </div>
          )}
          {title && (
            <TextWithBr h1 className={styles.title} tag="h1" content={title} />
          )}
          {subtitle && (
            <h2 className={styles.subtitle}>
              <RichText content={subtitle.json} />
            </h2>
          )}
          {subSubTitle && (
            <p className={styles.subSubTitle}>
              <RichText content={subSubTitle.json} />
            </p>
          )}

          <div
            className={classnames({
              [styles.buttonsWrapper]: buttonText || secondButtonText,
            })}
          >
            {buttonText && (
              <SmartLink
                to={buttonUrl}
                className={styles.button}
                aria-label={buttonText}
              >
                {buttonText}
              </SmartLink>
            )}
            {secondButtonText && (
              <SmartLink
                to={secondButtonUrl}
                className={styles.secondButton}
                aria-label={secondButtonText}
              >
                {secondButtonText}
              </SmartLink>
            )}
          </div>
        </div>
        {vidyardEmbed && (
          <div
            className={styles.graphic}
            dangerouslySetInnerHTML={{
              __html: removeSmartQuotes(vidyardEmbed.vidyardEmbed),
            }}
          />
        )}
        {!vidyardEmbed && graphic && (
          <div className={styles.graphic}>
            <Media
              className={classnames({
                [styles.hero_video]: graphic.file.contentType.includes('video'),
                [styles.hero_image]:
                  !graphic.file.contentType.includes('video'),
                [styles.drop_shadow]: addDropShadow,
              })}
              localFile={graphic.fluid}
              imageFormat="fluid"
              fileUrl={graphic.file.url}
              type={graphic.file.contentType}
              poster={videoPoster}
            />
          </div>
        )}
      </div>
    </section>
  );
}
