import React from 'react';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';
import colorTheme from 'helpers/colorTheme';
import { graphql } from 'gatsby';
import RichText from 'components/richText';
import SmartLink from './smartLink';
import styles from './longText.module.scss';

export default function LongText({
  title,
  rte,
  markdownEditor,
  sectionId,
  backgroundColor,
  comesFromContainer,
  menuLinks,
  isFirstElement,
  richType,
}) {
  const [containerRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const classicPageTemplate = richType === 'classic-page-template';
  const containerTemplate = richType === 'long-text-container';
  const solutionsTemplate = richType === 'solutions-template';
  const defaultTemplate = !richType || richType === 'default';
  const InnerElementTag = isFirstElement ? 'h1' : 'h2';
  const hasMenu = menuLinks?.length > 0;

  return (
    <section
      id={sectionId}
      ref={containerRef}
      style={colorTheme('background-color', backgroundColor)}
      className={classnames(styles.container, {
        [styles.revealed]: inView,
        [styles.longTextContainer]: isFirstElement,
        [styles.addContainerRte]: containerTemplate,
      })}
    >
      {menuLinks?.length > 0 && (
        <div className={styles.menuLinksContainer}>
          <div className={styles.menu}>
            {menuLinks.map(({ key, value }) => (
              <SmartLink className={styles.menu_link} to={value}>
                {key}
              </SmartLink>
            ))}
          </div>
        </div>
      )}

      {/* when long text is a container we should remove the
      inner wrapper to have the 100% of the width screen */}
      {containerTemplate ? (
        <>
          {(rte || markdownEditor) && (
            <>
              {title && (
                <InnerElementTag className={styles.title}>
                  {title}
                </InnerElementTag>
              )}
              <div className={styles.richContainer}>
                <RichText
                  content={rte.json || rte}
                  html={markdownEditor?.childMarkdownRemark?.html || null}
                  className={solutionsTemplate ? 'template' : null}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <div
          className={classnames(styles.inner, {
            [styles.addCustomTemplate]: solutionsTemplate || comesFromContainer,
            [styles.addDefaultRte]: defaultTemplate,
            [styles.addClassicPageTemplate]: classicPageTemplate && !hasMenu,
            [styles.hasMenu]: hasMenu,
          })}
        >
          {title && (
            <InnerElementTag className={styles.title}>{title}</InnerElementTag>
          )}
          {(rte || markdownEditor) && (
            <div className={styles.richContainer}>
              <RichText
                content={rte.json || rte}
                html={markdownEditor?.childMarkdownRemark?.html || null}
                className={solutionsTemplate ? 'template' : null}
              />
            </div>
          )}
        </div>
      )}
    </section>
  );
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const longTextFragment = graphql`
  fragment LongTextFragment on Node {
    ... on ContentfulLongText {
      title
      sectionId
      backgroundColor
      richType
      rte {
        json
      }
      menuLinks {
        key
        value
      }
      markdownEditor {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
